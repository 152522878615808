import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { ReactComponent as ArrowDown } from "../../assets/elevva-pro-icons/arrowDown.svg";
import { ReactComponent as DeleteTag } from "../../assets/elevva-pro-icons/delete-tag.svg";
import { ReactComponent as EditTag } from "../../assets/elevva-pro-icons/edit-tag.svg";
import { ReactComponent as FiltersTable } from "../../assets/elevva-pro-icons/filters-table.svg";
import { ReactComponent as SearchInvidual } from "../../assets/elevva-pro-icons/search-individual-table.svg";
import { ReactComponent as SearchIconAlone } from "../../assets/elevva-pro-icons/searchIconAlone.svg";
import { ReactComponent as ArrowLeft } from "../../assets/elevva-pro-icons/table-arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/elevva-pro-icons/table-arrow-right.svg";
import { ReactComponent as TagIcon } from "../../assets/elevva-pro-icons/tag-icon.svg";
import { ReactComponent as ReloadIcon } from "../../assets/elevva-pro-icons/reloadIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/elevva-pro-icons/edit-icon.svg";
import jsPDF from "jspdf";
import { DropdownList } from "react-widgets";
import {
  ButtonArrow,
  ButtonMob,
  instance,
  instanceMob,
  laptop1600,
  laptopMax1350,
} from "../../utils/utils";
import UserDetailComponent from "./user-detail";
import moment from "moment";

const UserAdmin = (props) => {
  const [users, setUsers] = useState([]);
const [selectFilterTag, setSelectFilterTag] = useState(null);
const [tags, setTags] = useState([]);
const [detailCompany, setDetailCompany] = useState(null);
const [tagsSelected, setTagsSelected] = useState([]);
const [pageNext, setPageNext] = useState(null);
const [pageBack, setPageBack] = useState(null);
const [count, setCount] = useState(10);
const [page, setPage] = useState(1);
const [dataBackup, setDataBackup] = useState({});
const [modal, setModal] = useState(null);
const [modalNewUser, setModalNewUser] = useState(null);
const [modalFilters, setModalFilters] = useState(null);
const [modalError, setModalError] = useState(null);
const [displayCorrect, setDisplayCorrect] = useState(null);
const [ErroraGregado, seterroraGregado] = useState(null);
const [ErroraGregadoCreateUser, seterroraGregadoCreateUser] = useState(null);
const [confirm, setModalConfirm] = useState(null);
const [userSelected, setUserSelected] = useState([]);
const [userDetail, setUserDetail] = useState(null);
const [stepUser, setStepUser] = useState(1);
const [userTriage, setUserTriage] = useState(null);
const [totalMemberships, settotalMemberships] = useState(null);
const [planesMob, setPlanesMob] = useState(null);
const [imageBase64User, setImageBase64User] = useState(null);
const [loaderNewUser, setLoaderNewUser] = useState(false);

const [filterUserActive, setFilterUserActive] = useState("True");

function getPlansMob() {
  instanceMob
    .get(`admin/plan/preregister/list`)
    .then((res) => res.data)
    .then((data) => {
      setPlanesMob(data);
    })
    .catch((err) => console.error(err));
}
useEffect(() => {
  if (stepUser === 2) {
    function getTriage() {
      instance
        .get(`memberships/corp/company/members/${userDetail.user_id}/triage`)
        .then((res) => res.data)
        .then((data) => {
          setUserTriage(data);
        })
        .catch((err) => console.error(err));
    }
    getTriage();

    function toDataURL(url, callback) {
      const img = new Image(); // Dynamically create the img element
      img.crossOrigin = "Anonymous"; // Enable CORS for the image
      img.onload = function () {
        const canvas = document.createElement("canvas"); // Create a canvas element
        const ctx = canvas.getContext("2d");

        const diameter = Math.min(img.width, img.height);
        const radius = diameter / 2;

        // Configurar el canvas
        canvas.width = diameter;
        canvas.height = diameter;

        // Dibujar un círculo
        ctx.beginPath();
        ctx.arc(radius, radius, radius, 0, Math.PI * 2, true);
        ctx.closePath();
        ctx.clip();

        // Dibujar la imagen
        ctx.drawImage(
          img,
          (img.width - diameter) / 2,
          (img.height - diameter) / 2,
          diameter,
          diameter,
          0,
          0,
          diameter,
          diameter
        );

        const dataURL = canvas.toDataURL("image/png"); // Convert canvas to Base64
        callback(dataURL); // Pass the Base64 string to the callback
      };
      img.onerror = function () {
        console.error(
          "Failed to load the image. Check the URL or CORS policy."
        );
      };
      img.src = url; // Set the image source
    }
    if (userDetail.profile_pic) {
      toDataURL(userDetail.profile_pic, function (dataUrl) {
        setImageBase64User(dataUrl);
      });
    }
  }
  getPlansMob();
}, [stepUser]);
useEffect(() => {
  if (modalNewUser) handleNewUser();
}, [loaderNewUser]);
useEffect(() => {
  if (modalNewUser && ErroraGregadoCreateUser == "" && modalNewUser)
    handleNewUser();
  if (ErroraGregadoCreateUser && modalNewUser) handleNewUser();
}, [ErroraGregadoCreateUser]);
useEffect(() => {
  if (ErroraGregado) handlerTags();
}, [ErroraGregado]);
useEffect(() => {
  if (tagsSelected.length > 0) handlerTags();
}, [tagsSelected]);
useEffect(() => {
  if (modal && tags) handlerTags();
}, [tags]);
// &tags=13  TODO
function handlerFilterByTag(idTag) {
  setSelectFilterTag(idTag);
  instance
    .get(
      `memberships/corp/company/members?active=${filterUserActive}&tags=${idTag}`
    )
    .then((res) => res.data)
    .then((data) => {
      setUsers(data.results);
      setPageNext(data.next);
      setPageBack(data.previous);
      setCount(data.count);
      setModalFilters(null);
    })
    .catch((err) => console.error(err));
}
function getUsers() {
  setSelectFilterTag(null);
  instance
    .get(`memberships/corp/company/members?active=${filterUserActive}`)
    .then((res) => res.data)
    .then((data) => {
      setUsers(data.results);
      setCount(data.count);
      setPageNext(data.next);
      setPageBack(data.previous);
      setDataBackup(data);
    })
    .catch((err) => console.error(err));
}
function getUsersFilter(value) {
  instance
    .get(
      `memberships/corp/company/members?search=${value}&active=${filterUserActive}`
    )
    .then((res) => res.data)
    .then((data) => {
      setUsers(data.results);
      setPageNext(data.next);
      setPageBack(data.previous);
      setCount(data.count);
    })
    .catch((err) => console.error(err));
}
function getUsersPage(value) {
  let url = value
    ? value + "&active=True"
    : `memberships/corp/company/members?active=${filterUserActive}`;

  instance
    .get(url)
    .then((res) => res.data)
    .then((data) => {
      setUsers(data.results);
      setPageNext(data.next);
      setPageBack(data.previous);
    })
    .catch((err) => console.error(err));
}
function getTags() {
  instance
    .get("memberships/corp/tags")
    .then((res) => res.data)
    .then((data) => {
      setTags(data.results);
    })
    .catch((err) => console.error(err));
}
function updateFilterTag(e) {
  instance
    .get(`memberships/corp/tags?search=${e.target.value}`)
    .then((res) => res.data)
    .then((data) => {
      setTags(data.results);
    })
    .catch((err) => console.error(err));
}
function filtersUser() {
  let styleModal = {
    display: "flex",
    position: "fixed",
    zIndex: 999,
    whiteSpace: "pre-line",
  };
  setModalFilters(
    <div
      className="popUpFilters justify-content-center align-items-center"
      style={styleModal}
    >
      <div
        className="modalInfoContenido"
        style={{
          background: "white",
          borderRadius: "20px",
          boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
          padding: "2% 6%",
        }}
      >
        <div
          className=""
          style={{
            position: "absolute",
            top: "5%",
            right: "1%",
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={() => setModalFilters(null)}
        >
          <i className="bi bi-x-circle"></i>
        </div>
        <div
          className=" mb-3 p-3"
          style={{
            lineHeight: "120%",
            textAlign: "center",

            minWidth: "calc(18rem + 1vmin)",

            color: "var(--elevvabutonLogin_User)",
          }}
        >
          <PerfectScrollbar style={{ maxHeight: "15rem", padding: "0 1rem" }}>
            {tags.map((tag, i) => (
              <div
                onClick={() => handlerFilterByTag(tag.id)}
                className="d-flex py-2 cursor-pointer"
                key={i}
                style={{
                  borderBottom: "1px solid #9DACBD",
                  justifyContent: "flex-start",
                  minWidth: "10rem",
                  fontSize: "calc(.4rem + .4vw)",
                }}
              >
                <label className="custom-checkbox me-2">
                  <input
                    type="radio"
                    name="user"
                    checked={selectFilterTag && selectFilterTag === tag.id}
                    onClick={() => {}}
                  />
                  <span className="checkmark"></span>
                </label>{" "}
                <span>{tag.name}</span>
              </div>
            ))}
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
}
function assingNewTag(event) {
  let vectorUsers = userSelected;

  let sender_string =
    vectorUsers.length === 1 ? userSelected[0] : userSelected.join(",");
  let tags_strings =
    tagsSelected.length === 1 ? tagsSelected[0] : tagsSelected.join(",");

  let formData = new FormData();
  formData.append("users_id", sender_string);
  formData.append("tags_id", tags_strings);

  instance
    .post(`memberships/corp/tags/assign`, formData)
    .then((res) => {
      if (res.data) {
        let styleModal = {
          display: "flex",
          position: "fixed",
          zIndex: 999,
          whiteSpace: "pre-line",
        };
        getUsers();
        setTagsSelected([]);

        setModal(
          <div
            className="popUpNewUser justify-content-center align-items-center"
            style={styleModal}
          >
            <div
              className="modalInfoContenido"
              style={{
                background: "white",
                borderRadius: "20px",
                boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
                padding: "2% 6%",
              }}
            >
              <div
                className=""
                style={{
                  position: "absolute",
                  top: "5%",
                  right: "1%",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  color: " #9DACBD",
                  cursor: "pointer",
                }}
                onClick={() => setModal(null)}
              >
                <i className="bi bi-x-circle"></i>
              </div>
              <div
                className=" mb-3 py-3"
                style={{
                  lineHeight: "120%",
                  textAlign: "center",

                  minWidth: "calc(18rem + 1vmin)",

                  color: "var(--elevvabutonLogin_User)",
                }}
              >
                <br />
                asignado(s)
                <br />
                <strong>correctamente.</strong>
                <br />
              </div>
              <form id="form-teepassword-change" onSubmit={hideModal}>
                <div className="col-12">
                  <div className="col-12 btnAction_pruebas letraspopfi2">
                    <ButtonMob
                      colorButton={"var(--elevvabutonLogin_User)"}
                      text="Confirmar"
                      className={"mb-3 mt-3 letraspopfi2actualizar"}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        );
      }
    })
    .catch((err) => {
      let msg_error = err.response.data;
      if (msg_error) {
        seterroraGregado(msg_error);

        setDisplayCorrect("flex");
        setTimeout(() => {
          setDisplayCorrect("none");
        }, 5000);
      }
    });

  event.preventDefault();
}
function createNewTag(event) {
  let value = event.target[0].value;

  var formData = new FormData();
  formData.append("name", value.trim());

  instance
    .post(`memberships/corp/tags/`, formData)
    .then((res) => {
      if (res.data) {
        getTags();

        let styleModal = {
          display: "flex",
          position: "fixed",
          zIndex: 999,
          whiteSpace: "pre-line",
        };
        setModal(
          <div
            className="popUpNewUser justify-content-center align-items-center"
            style={styleModal}
          >
            <div
              className="modalInfoContenido"
              style={{
                background: "white",
                borderRadius: "20px",
                boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
                padding: "2% 6%",
              }}
            >
              <div
                className=""
                style={{
                  position: "absolute",
                  top: "5%",
                  right: "1%",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  color: " #9DACBD",
                  cursor: "pointer",
                }}
                onClick={() => setModal(null)}
              >
                <i className="bi bi-x-circle"></i>
              </div>
              <div
                className=" mb-3 py-3"
                style={{
                  lineHeight: "120%",
                  textAlign: "center",

                  minWidth: "calc(18rem + 1vmin)",

                  color: "var(--elevvabutonLogin_User)",
                }}
              >
                <br />
                Se ha creado
                <br />
                <strong>correctamente.</strong>
                <br />
              </div>
              <form id="form-teepassword-change" onSubmit={hideModal}>
                <div className="col-12">
                  <div className="col-12 btnAction_pruebas letraspopfi2">
                    <ButtonMob
                      colorButton={"var(--elevvabutonLogin_User)"}
                      text="Confirmar"
                      className={"mb-3 mt-3 letraspopfi2actualizar"}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        );
      }
    })
    .catch((err) => {
      let msg_error = err.response.data;
      if (msg_error) {
        seterroraGregado(msg_error);

        setDisplayCorrect("flex");
        setTimeout(() => {
          setDisplayCorrect("none");
        }, 5000);
      }
    });

  event.preventDefault();
}
function createNewUser(event) {
  if (!event.target) return;
  let value = event.target[0].value;
  let membership = event.target[1].value;
  let plan = event.target[3].value;

  var formData = new FormData();
  formData.append("email", value.trim());
  formData.append("plan_id", plan);
  formData.append("membership", membership);
  setLoaderNewUser(true);
  instance
    .post(`memberships/corp/company/members/preregister`, formData)
    .then((res) => {
      if (res.data) {
        // setLoaderNewUser(false);
        setModalNewUser(null);
        setModalConfirm(null);
        let styleModal = {
          display: "flex",
          position: "fixed",
          zIndex: 999,
          whiteSpace: "pre-line",
        };
        setModal(
          <div
            className="popUpNewUser justify-content-center align-items-center"
            style={styleModal}
          >
            <div
              className="modalInfoContenido"
              style={{
                background: "white",
                borderRadius: "20px",
                boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
                padding: "2% 6%",
              }}
            >
              <div
                className=""
                style={{
                  position: "absolute",
                  top: "5%",
                  right: "1%",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  color: " #9DACBD",
                  cursor: "pointer",
                }}
                onClick={() => setModal(null)}
              >
                <i className="bi bi-x-circle"></i>
              </div>
              <div
                className=" mb-3 py-3"
                style={{
                  lineHeight: "120%",
                  textAlign: "center",

                  minWidth: "calc(18rem + 1vmin)",

                  color: "var(--elevvabutonLogin_User)",
                }}
              >
                <br />
                El usuario ha
                <br />
                <strong> sido agregado</strong>
                <br />
                <strong>correctamente.</strong>
                <br />
              </div>
              <form id="form-password-change" onSubmit={hideModal}>
                <div className="col-12">
                  <div className="col-12 btnAction_pruebas letraspopfi2">
                    <ButtonMob
                      colorButton={"var(--elevvabutonLogin_User)"}
                      text="Confirmar"
                      className={"mb-3 mt-3 letraspopfi2actualizar"}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        );
      }
    })
    .catch((err) => {
      let msg_error = err.response.data;
      if (msg_error) {
        seterroraGregadoCreateUser(msg_error);

        setDisplayCorrect("flex");
        setTimeout(() => {
          seterroraGregadoCreateUser("");
          setDisplayCorrect("none");
        }, 5000);
      }
    })
    .finally(() => {
      setLoaderNewUser(false);
    });

  event.preventDefault();
}

function addTagUser() {
  if (userSelected.length > 0) {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalError(
      <div
        className="popUpNewUser justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "10%",
          }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "1%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModalError(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div
            className=" mb-3 py-3"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <div
              className=" pt-3 my-4"
              style={{
                lineHeight: "100%",
                textAlign: "center",

                color: "var(--elevvabutonLogin_User)",
              }}
            >
              Debes seleccionar <br></br>
              <strong> un usuario</strong>
            </div>
            <ButtonMob
              handleClick={() => setModalError(null)}
              colorButton={"var(--elevvabutonLogin_User)"}
              text={"Continuar"}
              className={"mb-3 mt-3 letraspopfi2actualizar"}
            />
          </div>
        </div>
      </div>
    );
  } else {
    var formData = new FormData();
    formData.append("members_to_deactivate", userSelected.user_id);

    instance
      .post(
        `memberships/corp/${userSelected.membership_id}/members/deactivate`,
        formData
      )
      .then((res) => {
        if (res.data) {
          let styleModal = {
            display: "flex",
            position: "fixed",
            zIndex: 999,
            whiteSpace: "pre-line",
          };
          getUsersFilter("");

          setModal(
            <div
              className="popUpNewUser justify-content-center align-items-center"
              style={styleModal}
            >
              <div
                className="modalInfoContenido"
                style={{
                  background: "white",
                  borderRadius: "20px",
                  boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
                  padding: "2% 6%",
                }}
              >
                <div
                  className=""
                  style={{
                    position: "absolute",
                    top: "5%",
                    right: "1%",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    color: " #9DACBD",
                    cursor: "pointer",
                  }}
                  onClick={() => setModal(null)}
                >
                  <i className="bi bi-x-circle"></i>
                </div>
                <div
                  className=" mb-3 py-3"
                  style={{
                    lineHeight: "120%",
                    textAlign: "center",

                    minWidth: "calc(18rem + 1vmin)",

                    color: "var(--elevvabutonLogin_User)",
                  }}
                >
                  <br />
                  El usuario ha
                  <br />
                  <strong> sido eliminado</strong>
                  <br />
                  <strong>correctamente.</strong>
                  <br />
                </div>

                <div className="col-12">
                  <div className="col-12 btnAction_pruebas letraspopfi2">
                    <ButtonMob
                      handleClick={() => {
                        setModal(null);
                      }}
                      colorButton={"var(--elevvabutonLogin_User)"}
                      text="Confirmar"
                      className={"mb-3 mt-3 letraspopfi2actualizar"}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })
      .catch((err) => {
        let msg_error = err.response.data;
        if (msg_error) {
          seterroraGregado(msg_error);

          setDisplayCorrect("flex");
          setTimeout(() => {
            setDisplayCorrect("none");
          }, 5000);
        }
      });
  }
}
function deleteTag(tag) {
  let styleModal = {
    display: "flex",
    position: "fixed",
    zIndex: 999,
    whiteSpace: "pre-line",
  };
  const formData = new FormData();
  formData.append("name", tag.name);
  setModal(
    <div
      className="popUpNewUser justify-content-center align-items-center"
      style={styleModal}
    >
      <div
        className="modalInfoContenido"
        style={{
          background: "white",
          borderRadius: "20px",
          boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
          padding: "2% 6%",
        }}
      >
        <div
          className=""
          style={{
            position: "absolute",
            top: "5%",
            right: "1%",
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={() => setModal(null)}
        >
          <i className="bi bi-x-circle"></i>
        </div>
        <div
          className=" mb-3 py-3"
          style={{
            lineHeight: "120%",
            textAlign: "center",

            minWidth: "calc(18rem + 1vmin)",

            color: "var(--elevvabutonLogin_User)",
          }}
        >
          <br />
          Esta seguro de eliminar a
          <br />
          <strong> {tag.name} ??</strong>
          <br />
          <br />
        </div>

        <div className="col-12">
          <div className="col-12 btnAction_pruebas letraspopfi2">
            <ButtonMob
              handleClick={() => {
                instance
                  .delete(`memberships/corp/tags/${tag.id}`, formData)
                  .then((res) => {
                    setModal(null);
                    getTags();
                  })
                  .catch((err) => {
                    let msg_error = err.response.data;
                    if (msg_error) {
                      console.log(err);
                    }
                  });
              }}
              colorButton={"var(--elevvabutonLogin_User)"}
              text="Confirmar"
              className={"mb-3 mt-3 letraspopfi2actualizar"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
function editTag(event, tag) {
  let styleModal = {
    display: "flex",
    position: "fixed",
    zIndex: 999,
    whiteSpace: "pre-line",
  };
  let value = event.target[0].value;
  const formData = new FormData();
  formData.append("name", value);

  instance
    .patch(`memberships/corp/tags/${tag.id}`, formData)
    .then((res) => {
      getTags();
      setModal(
        <div
          className="popUpNewUser justify-content-center align-items-center"
          style={styleModal}
        >
          <div
            className="modalInfoContenido"
            style={{
              background: "white",
              borderRadius: "20px",
              boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
              padding: "2% 6%",
            }}
          >
            <div
              className=""
              style={{
                position: "absolute",
                top: "5%",
                right: "1%",
                fontWeight: "bold",
                fontSize: "1.5rem",
                color: " #9DACBD",
                cursor: "pointer",
              }}
              onClick={() => setModal(null)}
            >
              <i className="bi bi-x-circle"></i>
            </div>
            <div
              className=" mb-3 py-3"
              style={{
                lineHeight: "120%",
                textAlign: "center",

                minWidth: "calc(18rem + 1vmin)",

                color: "var(--elevvabutonLogin_User)",
              }}
            >
              <br />
              Editada
              <br />
              <strong>correctamente</strong>
              <br />
              <br />
            </div>

            <div className="col-12">
              <div className="col-12 btnAction_pruebas letraspopfi2">
                <ButtonMob
                  handleClick={() => {
                    setModal(null);
                  }}
                  colorButton={"var(--elevvabutonLogin_User)"}
                  text="Confirmar"
                  className={"mb-3 mt-3 letraspopfi2actualizar"}
                />
              </div>
            </div>
          </div>
        </div>
      );
    })
    .catch((err) => {
      let msg_error = err.response.data;
      if (msg_error) {
        console.log(err);
      }
    });
}
function editMembership(event, user_id) {
  let styleModal = {
    display: "flex",
    position: "fixed",
    zIndex: 999,
    whiteSpace: "pre-line",
  };

  event.preventDefault();

  const formData = new FormData(event.target);
  const selectedMembership = formData.get("membership-admin");

  formData.append("user_id", user_id);
  formData.append("membership_id", selectedMembership);
  instance
    .post(`memberships/corp/members/change`, formData)
    .then((res) => {
      getUsers();
      setModal(
        <div
          className="popUpNewUser justify-content-center align-items-center"
          style={styleModal}
        >
          <div
            className="modalInfoContenido"
            style={{
              background: "white",
              borderRadius: "20px",
              boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
              padding: "2% 6%",
            }}
          >
            <div
              className=""
              style={{
                position: "absolute",
                top: "5%",
                right: "1%",
                fontWeight: "bold",
                fontSize: "1.5rem",
                color: " #9DACBD",
                cursor: "pointer",
              }}
              onClick={() => setModal(null)}
            >
              <i className="bi bi-x-circle"></i>
            </div>
            <div
              className=" mb-3 py-3"
              style={{
                lineHeight: "120%",
                textAlign: "center",

                minWidth: "calc(18rem + 1vmin)",

                color: "var(--elevvabutonLogin_User)",
              }}
            >
              <br />
              Editado
              <br />
              <strong>correctamente</strong>
              <br />
              <br />
            </div>

            <div className="col-12">
              <div className="col-12 btnAction_pruebas letraspopfi2">
                <ButtonMob
                  handleClick={() => {
                    setModal(null);
                  }}
                  colorButton={"var(--elevvabutonLogin_User)"}
                  text="Confirmar"
                  className={"mb-3 mt-3 letraspopfi2actualizar"}
                />
              </div>
            </div>
          </div>
        </div>
      );
    })
    .catch((err) => {
      let msg_error = err.response.data;
      if (msg_error) {
        console.log(err);
      }
    });
}
function deleteUser() {
  var formData = new FormData();
  let vectorUsers = userSelected;

  let sender_string =
    vectorUsers.length === 1 ? vectorUsers[0] : vectorUsers.join(",");
  formData.append("members_to_deactivate", sender_string);
  instance
    .post(`memberships/corp/members/deactivate`, formData)
    .then((res) => {
      if (res.data) {
        let styleModal = {
          display: "flex",
          position: "fixed",
          zIndex: 999,
          whiteSpace: "pre-line",
        };
        getUsersFilter("");

        setModal(
          <div
            className="popUpNewUser justify-content-center align-items-center"
            style={styleModal}
          >
            <div
              className="modalInfoContenido"
              style={{
                background: "white",
                borderRadius: "20px",
                boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
                padding: "2% 6%",
              }}
            >
              <div
                className=""
                style={{
                  position: "absolute",
                  top: "5%",
                  right: "1%",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  color: " #9DACBD",
                  cursor: "pointer",
                }}
                onClick={() => setModal(null)}
              >
                <i className="bi bi-x-circle"></i>
              </div>
              <div
                className=" mb-3 py-3"
                style={{
                  lineHeight: "120%",
                  textAlign: "center",

                  minWidth: "calc(18rem + 1vmin)",

                  color: "var(--elevvabutonLogin_User)",
                }}
              >
                <br />
                usuario(s)
                <br />
                <strong> eliminado(s)</strong>
                <br />
                <strong>correctamente.</strong>
                <br />
              </div>

              <div className="col-12">
                <div className="col-12 btnAction_pruebas letraspopfi2">
                  <ButtonMob
                    handleClick={() => {
                      setModal(null);
                      setUserSelected([]);
                    }}
                    colorButton={"var(--elevvabutonLogin_User)"}
                    text="Confirmar"
                    className={"mb-3 mt-3 letraspopfi2actualizar"}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }
    })
    .catch((err) => {
      let msg_error = err.response.data;
      if (msg_error) {
        seterroraGregado(msg_error);

        setDisplayCorrect("flex");
        setTimeout(() => {
          setDisplayCorrect("none");
        }, 5000);
      }
    });
}
const hideModal = () => {
  setModal(null);
};

async function handleDownloadExcel() {
  instance.get(`memberships/corp/company/members/excel`);

  try {
    const response = await instance.get(
      "memberships/corp/company/members/excel",
      {
        responseType: "blob", // Importante para manejar archivos binarios
      }
    );

    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

    // Crear un enlace de descarga y asignar la URL del Blob
    const link = document.createElement("a");
    link.href = url;

    // Asignar un nombre al archivo Excel para la descarga
    link.setAttribute("download", "archivo_descargado.xlsx"); // Cambia el nombre según corresponda

    // Añadir el enlace al DOM y simular el clic
    document.body.appendChild(link);
    link.click();

    // Limpiar después de la descarga
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error al descargar el archivo Excel:", error);
  }
}
function handleDeleteUser() {
  let styleModal = {
    display: "flex",
    position: "fixed",
    zIndex: 999,
    whiteSpace: "pre-line",
  };
  setModal(
    <div
      className="popUpNewUser justify-content-center align-items-center"
      style={styleModal}
    >
      <div
        className="modalInfoContenido"
        style={{
          background: "white",
          borderRadius: "20px",
          boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
          padding: "6% 10%",
        }}
      >
        <div
          className=""
          style={{
            position: "absolute",
            top: "5%",
            right: "1%",
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={() => setModal(null)}
        >
          <i className="bi bi-x-circle"></i>
        </div>
        {userSelected.length > 0 ? (
          <div
            className=" pt-3 my-4 mx-auto"
            style={{
              lineHeight: "100%",
              textAlign: "center",
              maxWidth: "30rem",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            ¿Estas seguro de <br></br>
            <strong>
              eliminar a{" "}
              {userSelected.length >= 10
                ? " a todos los usuarios en esta búsqueda"
                : userSelected
                    .map((userId) => {
                      // Encuentra el usuario correspondiente
                      const user = users.find((u) => u.user_id === userId);
                      // Devuelve el full_name si el usuario fue encontrado
                      return user ? user.full_name : null;
                    })
                    // Filtra los valores nulos por si algún usuario no fue encontrado
                    .filter((fullName) => fullName !== null)
                    .join(", ")}
              ?{" "}
            </strong>
          </div>
        ) : (
          <div
            className=" pt-3 my-4"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            Debes seleccionar <br></br>
            <strong> un usuario</strong>
          </div>
        )}
        <ButtonMob
          handleClick={
            userSelected.length > 0 ? () => deleteUser() : () => setModal(null)
          }
          colorButton={"var(--elevvabutonLogin_User)"}
          text={userSelected ? "Confirmar" : "Continuar"}
          className={"mb-3 mt-3 letraspopfi2actualizar"}
        />
      </div>
    </div>
  );
}

function editMembershipClient(user_id) {
  let styleModal = {
    display: "flex",
    position: "fixed",
    zIndex: 999,
    whiteSpace: "pre-line",
  };

  setModal(
    <div
      className="popUpNewUser justify-content-center align-items-center"
      style={styleModal}
    >
      <div
        className="modalInfoContenido"
        style={{
          background: "white",
          borderRadius: "20px",
          boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
          padding: "2% 6%",
        }}
      >
        <div
          className=""
          style={{
            position: "absolute",
            top: "5%",
            right: "1%",
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={() => setModal(null)}
        >
          <i className="bi bi-x-circle"></i>
        </div>
        <div
          className=" mb-3 py-3"
          style={{
            lineHeight: "100%",
            textAlign: "center",

            color: "var(--elevvabutonLogin_User)",
          }}
        >
          Editar <strong>membresía</strong>
        </div>
        <form
          id="form-membership-edit"
          onSubmit={(e) => {
            editMembership(e, user_id);
          }}
        >
          <PerfectScrollbar style={{ maxHeight: "7rem", padding: "0 1rem" }}>
            {totalMemberships.map((mem) => (
              <div
                key={mem.id}
                className="py-2 d-flex align-items-center gap-2"
                style={{
                  borderBottom: "1px solid #9DACBD",
                  justifyContent: "space-between",
                  minWidth: "15rem",
                }}
              >
                <div>
                  <input
                    type="radio"
                    name="membership-admin"
                    value={mem.id}
                  ></input>{" "}
                  <span>{mem.name}</span>
                </div>
              </div>
            ))}
          </PerfectScrollbar>
          <div className="col-12">
            <div className="col-12 btnAction_pruebas letraspopfi2">
              <ButtonMob
                colorButton={"var(--elevvabutonLogin_User)"}
                text="Confirmar"
                className={"mb-3 mt-3 letraspopfi2actualizar"}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
function handleNewUser() {
  let styleModal = {
    display: "flex",
    position: "fixed",
    zIndex: 999,
    whiteSpace: "pre-line",
  };
  setModalNewUser(
    <div
      className="popUpNewUser justify-content-center align-items-center"
      style={styleModal}
    >
      <div
        className="modalInfoContenido"
        style={{
          background: "white",
          borderRadius: "20px",
          boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
          padding: "2% 6%",
        }}
      >
        <div
          className=""
          style={{
            position: "absolute",
            top: "5%",
            right: "1%",
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={() => setModalNewUser(null)}
        >
          <i className="bi bi-x-circle"></i>
        </div>
        <div
          className=" mb-3 py-3"
          style={{
            lineHeight: "100%",
            textAlign: "center",

            color: "var(--elevvabutonLogin_User)",
          }}
        >
          Nuevo <strong>Usuario</strong>
        </div>
        <form
          id="form-password-change"
          onSubmit={(e) => {
            createNewUser(e);
          }}
        >
          <input
            className="form-control input-style-elevva "
            style={{
              minWidth: "calc(18rem + 1vmin)",
              fontSize: "calc(.4rem + .4vw)",
            }}
            name="tester"
            placeholder="Ingresa el correo electrónico"
            required
          />
          <div className="d-flex  my-2 w-100 " id="location-select">
            <DropdownList
              style={{ width: "100%" }}
              busy
              name="membership"
              busySpinner={<ArrowDown></ArrowDown>}
              className={"input-style-elevva-graphics"}
              id="optionSummary"
              placeholder={"Membresías"}
              data={
                totalMemberships &&
                totalMemberships.map((memb) => ({
                  id: memb.id,
                  name: memb.name,
                }))
              }
              dataKey="id"
              textField="name"
              onChange={() => {}}
              renderListItem={({ item }) => (
                <div
                  className="w-100 mb-1"
                  style={{ borderBottom: "1px solid #9DACBD" }}
                >
                  <div>{item.name}</div>
                </div>
              )}
            />
          </div>
          {localStorage.mob_integration &&
            JSON.parse(localStorage.mob_integration) && (
              <div className="d-flex  my-2 w-100 " id="location-select">
                <DropdownList
                  style={{ width: "100%" }}
                  busy
                  busySpinner={<ArrowDown></ArrowDown>}
                  className={"input-style-elevva-graphics"}
                  id="optionSummary"
                  placeholder={"Planes"}
                  name="plan_id"
                  data={
                    planesMob &&
                    planesMob.map((memb) => ({
                      id: memb.id,
                      name: memb.name,
                    }))
                  }
                  dataKey="id"
                  textField="name"
                  onChange={() => {}}
                  renderListItem={({ item }) => (
                    <div
                      className="w-100 mb-1"
                      style={{ borderBottom: "1px solid #9DACBD" }}
                    >
                      <div>{item.name}</div>
                    </div>
                  )}
                />
              </div>
            )}
          <br />
          <div
            id="new_user_error"
            className=""
            style={{
              display: `${displayCorrect}`,
              color: "var(--elevva)",
              maxWidth: "calc(18rem + 1vmin)",
              fontSize: "calc(.3rem + .3vw)",
            }}
          >
            {ErroraGregadoCreateUser ? ErroraGregadoCreateUser : null}
          </div>
          <div className="col-12">
            <div className="col-12 btnAction_pruebas letraspopfi2">
              <ButtonMob
                disabled={loaderNewUser}
                colorButton={"var(--elevvabutonLogin_User)"}
                text={loaderNewUser ? "Cargando..." : "Confirmar"}
                className={"mb-3 mt-3 letraspopfi2actualizar"}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
function editModalTag(tag) {
  let styleModal = {
    display: "flex",
    position: "fixed",
    zIndex: 999,
    whiteSpace: "pre-line",
  };
  setModal(
    <div
      className="popUpNewUser justify-content-center align-items-center"
      style={styleModal}
    >
      <div
        className="modalInfoContenido"
        style={{
          background: "white",
          borderRadius: "20px",
          boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
          padding: "2% 6%",
        }}
      >
        <div
          className=""
          style={{
            position: "absolute",
            top: "5%",
            right: "1%",
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={() => setModal(null)}
        >
          <i className="bi bi-x-circle"></i>
        </div>
        <div
          className=" mb-3 py-3"
          style={{
            lineHeight: "100%",
            textAlign: "center",

            color: "var(--elevvabutonLogin_User)",
          }}
        >
          Editar <strong>etiqueta</strong>
        </div>
        <form
          id="form-password-change"
          onSubmit={(e) => {
            e.preventDefault();
            editTag(e, tag);
          }}
        >
          {" "}
          <input
            className="form-control input-style-elevva "
            style={{
              minWidth: "calc(18rem + 1vmin)",
              fontSize: "calc(.4rem + .4vw)",
            }}
            name="tag_name_edit"
            placeholder="Introduce el nombre de la etiqueta"
            required
          />
          <br />
          <div
            id="new_user_er"
            className=""
            style={{
              display: `${displayCorrect}`,
              color: "var(--elevva)",
              maxWidth: "calc(18rem + 1vmin)",
              fontSize: "calc(.3rem + .3vw)",
            }}
          >
            {ErroraGregado ? ErroraGregado.password : null}
          </div>
          <div className="col-12">
            <div className="col-12 btnAction_pruebas letraspopfi2">
              <ButtonMob
                colorButton={"var(--elevvabutonLogin_User)"}
                text="Confirmar"
                className={"mb-3 mt-3 letraspopfi2actualizar"}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
function assignTag() {
  let styleModal = {
    display: "flex",
    position: "fixed",
    zIndex: 999,
    whiteSpace: "pre-line",
  };
  setModal(
    <div
      className="popUpNewUser justify-content-center align-items-center"
      style={styleModal}
    >
      <div
        className="modalInfoContenido"
        style={{
          background: "white",
          borderRadius: "20px",
          boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
          padding: "2% 6%",
        }}
      >
        <div
          className=""
          style={{
            position: "absolute",
            top: "5%",
            right: "1%",
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={() => setModal(null)}
        >
          <i className="bi bi-x-circle"></i>
        </div>
        <div
          className=" mb-3 py-3"
          style={{
            lineHeight: "100%",
            textAlign: "center",

            color: "var(--elevvabutonLogin_User)",
          }}
        ></div>
        <form
          id="assign-tag-form"
          onSubmit={(e) => {
            assingNewTag(e);
          }}
        >
          {" "}
          <br />
          <div
            className=" mb-3 py-3"
            style={{
              lineHeight: "120%",
              textAlign: "center",

              minWidth: "calc(18rem + 1vmin)",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            <br />
            Estas seguro
            <br />
            <strong>de agregar etiquetas.</strong>
            <br />
          </div>
          <div
            id="new_user_error"
            className=""
            style={{
              display: `${displayCorrect}`,
              color: "var(--elevva)",
              maxWidth: "calc(18rem + 1vmin)",
              fontSize: "calc(.3rem + .3vw)",
            }}
          >
            {ErroraGregado ? ErroraGregado.password : null}
          </div>
          <div className="col-12">
            <div className="col-12 btnAction_pruebas letraspopfi2">
              <ButtonMob
                colorButton={"var(--elevvabutonLogin_User)"}
                text="Confirmar"
                className={"mb-3 mt-3 letraspopfi2actualizar"}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
function createTag() {
  let styleModal = {
    display: "flex",
    position: "fixed",
    zIndex: 999,
    whiteSpace: "pre-line",
  };
  setModal(
    <div
      className="popUpNewUser justify-content-center align-items-center"
      style={styleModal}
    >
      <div
        className="modalInfoContenido"
        style={{
          background: "white",
          borderRadius: "20px",
          boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
          padding: "2% 6%",
        }}
      >
        <div
          className=""
          style={{
            position: "absolute",
            top: "5%",
            right: "1%",
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={() => setModal(null)}
        >
          <i className="bi bi-x-circle"></i>
        </div>
        <div
          className=" mb-3 py-3"
          style={{
            lineHeight: "100%",
            textAlign: "center",

            color: "var(--elevvabutonLogin_User)",
          }}
        >
          Nueva <strong>etiqueta</strong>
        </div>
        <form
          id="form-password-change"
          onSubmit={(e) => {
            createNewTag(e);
          }}
        >
          {" "}
          <input
            className="form-control input-style-elevva "
            style={{
              minWidth: "calc(18rem + 1vmin)",
              fontSize: "calc(.4rem + .4vw)",
            }}
            name="tag_name"
            placeholder="Introduce el nombre de la nueva etiqueta"
            required
          />
          <br />
          <div
            id="new_user_error"
            className=""
            style={{
              display: `${displayCorrect}`,
              color: "var(--elevva)",
              maxWidth: "calc(18rem + 1vmin)",
              fontSize: "calc(.3rem + .3vw)",
            }}
          >
            {ErroraGregado ? ErroraGregado.password : null}
          </div>
          <div className="col-12">
            <div className="col-12 btnAction_pruebas letraspopfi2">
              <ButtonMob
                colorButton={"var(--elevvabutonLogin_User)"}
                text="Confirmar"
                className={"mb-3 mt-3 letraspopfi2actualizar"}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
function manageTags() {
  let styleModal = {
    display: "flex",
    position: "fixed",
    zIndex: 999,
    whiteSpace: "pre-line",
  };
  setModal(
    <div
      className="popUpNewUser justify-content-center align-items-center"
      style={styleModal}
    >
      <div
        className="modalInfoContenido"
        style={{
          background: "white",
          borderRadius: "20px",
          boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
          padding: "4% 10%",
        }}
      >
        <div
          className=""
          style={{
            position: "absolute",
            top: "5%",
            right: "1%",
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={() => setModal(null)}
        >
          <i className="bi bi-x-circle"></i>
        </div>
        <div
          className=" mb-3 py-3"
          style={{
            lineHeight: "100%",
            textAlign: "center",

            color: "var(--elevvabutonLogin_User)",
          }}
        >
          Gestionar <strong>Etiquetas</strong>
        </div>
        <form
          id="form-tags"
          onSubmit={(e) => {
            e.preventDefault();
            addTagUser(e);
          }}
        >
          <PerfectScrollbar style={{ maxHeight: "7rem", padding: "0 1rem" }}>
            {tags.map((tag) => (
              <div
                key={tag.id}
                className="py-2 d-flex align-items-center gap-2"
                style={{
                  borderBottom: "1px solid #9DACBD",
                  justifyContent: "space-between",
                  minWidth: "15rem",
                }}
              >
                <div>
                  <span>{tag.name}</span>
                </div>
                <div className="d-flex gap-2">
                  <div
                    className="cursor-pointer"
                    onClick={() => editModalTag(tag)}
                  >
                    <EditTag width={"100%"} />
                  </div>

                  <div
                    className="cursor-pointer"
                    onClick={() => deleteTag(tag)}
                    width={"2rem"}
                  >
                    {" "}
                    <DeleteTag width={"100%"} />
                  </div>
                </div>
              </div>
            ))}
          </PerfectScrollbar>
          <span> {ErroraGregado}</span>
        </form>
      </div>
    </div>
  );
}
function handlerTags() {
  let styleModal = {
    display: "flex",
    position: "fixed",
    zIndex: 999,
    whiteSpace: "pre-line",
  };
  setModal(
    <div
      className="popUpNewUser justify-content-center align-items-center"
      style={styleModal}
    >
      <div
        className="modalInfoContenido"
        style={{
          background: "white",
          borderRadius: "20px",
          boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
          padding: "4% 10%",
        }}
      >
        <div
          className=""
          style={{
            position: "absolute",
            top: "5%",
            right: "1%",
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={() => setModal(null)}
        >
          <i className="bi bi-x-circle"></i>
        </div>
        <div
          className=" mb-3 py-3"
          style={{
            lineHeight: "100%",
            textAlign: "center",

            color: "var(--elevvabutonLogin_User)",
          }}
        >
          <strong>Etiquetar como:</strong>
        </div>
        <div>
          <div style={{ position: "relative", marginLeft: "auto" }}>
            {" "}
            <input
              type="search"
              className="searchUsers "
              style={{
                borderBottom: "1px solid var(--elevvaTextSubtitle)",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",

                padding: "0.4rem 1rem",
              }}
              onChange={(e) => {
                updateFilterTag(e);
              }}
            ></input>
            <span className="search-icon-admin pe-5">
              <SearchIconAlone fontSize={10} />
            </span>
          </div>
        </div>
        <form
          id="form-tags"
          onSubmit={(e) => {
            e.preventDefault();
            addTagUser(e);
          }}
        >
          {tags.length > 0 ? (
            <PerfectScrollbar style={{ maxHeight: "7rem" }}>
              {tags.map((tag) => (
                <div
                  key={tag.id}
                  className="py-2 d-flex align-items-center gap-2"
                  style={{
                    borderBottom: "1px solid #9DACBD",
                    minWidth: "15rem",
                  }}
                >
                  <div>
                    <input
                      type="checkbox"
                      onChange={() => {
                        setTagsSelected((prev) => {
                          if (prev.includes(tag.id)) {
                            return prev.filter((item) => item !== tag.id);
                          }
                          return [...prev, tag.id];
                        });
                      }}
                    ></input>
                  </div>
                  <div>
                    <span>{tag.name}</span>
                  </div>
                </div>
              ))}
            </PerfectScrollbar>
          ) : (
            "No existen etiquetas"
          )}
          <span> {ErroraGregado}</span>
        </form>
        <ButtonMob
          handleClick={createTag}
          colorButton={"var(--elevvabutonLogin_User)"}
          text="Crear etiqueta"
          className={"mb-3 mt-3 letraspopfi2actualizar"}
        />
        {userSelected.length > 0 && tagsSelected.length > 0 && (
          <ButtonMob
            handleClick={assignTag}
            colorButton={"var(--elevvabutonLogin_User)"}
            text="Asignar etiqueta"
            className={"mb-3 mt-3 letraspopfi2actualizar"}
          />
        )}
        <ButtonMob
          handleClick={manageTags}
          colorButton={"var(--elevvabutonLogin_User)"}
          text="Gestionar etiquetas"
          className={"mb-3 mt-3 letraspopfi2actualizar"}
        />
      </div>
    </div>
  );
}

function getDetailCompany() {
  instance
    .get("memberships/corp/company")
    .then((res) => res.data)
    .then((data) => {
      setDetailCompany(data);
    })
    .catch((err) => console.error(err));
}
function searchUsers(e) {
  let valueLower = e.target.value.toLowerCase();
  if (valueLower == "") {
    setUsers(dataBackup.results.filter((memb) => memb.active === true));
    setCount(dataBackup.count);
    setPageNext(dataBackup.next);
    setPageBack(dataBackup.previous);
  } else {
    getUsersFilter(valueLower);
  }
}
function getMemberships() {
  instance
    .get("memberships/corp/memberships")
    .then((res) => res.data)
    .then((data) => {
      settotalMemberships(data);
    })
    .catch((err) => console.error(err));
}

useEffect(() => {
  getUsers();
  getTags();
  getDetailCompany();
  getMemberships();
}, []);
const convertToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
function changePage(value) {
  if (value == "next") {
    getUsersPage(pageNext);

    if (page * 10 - 10 >= count) {
      setPage(count);
    } else {
      setPage(page + 1);
    }
  }
  if (value == "previous") {
    getUsersPage(pageBack);
    if (page - 1 === 0) {
      setPage(1);
    } else {
      setPage(page - 1);
    }
  }
}

const generatePdf = async () => {
  // Referencia al div que queremos convertir en PDF

  let doc = new jsPDF("p", "mm", "a4");
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const logoUrl = "/assets/logo_normal_128.png"; // Ruta del logo en la carpeta pública
  const logoImage = await fetch(logoUrl).then((res) => res.blob());
  const logoBase64 = await convertToBase64(logoImage);

  doc.setFillColor(105, 130, 255);

  // Define the path for the curve
  doc.moveTo(0, 260); // Punto inicial
  doc.curveTo(50, 290, 160, 290, 210, 260); // Control points ajustados
  doc.lineTo(210, 297); // Extender hasta el borde inferior
  doc.lineTo(0, 297); // Completar el camino // Complete the path

  // Fill the curve
  doc.fill();

  // Agregar el logo al PDF
  doc.addImage(logoBase64, "PNG", pageWidth / 2 - 12, 15, 25, 10, {
    align: "center",
  });
  // doc.addImage(

  doc.setTextColor("#394F65");

  doc.text("CONTRATO", pageWidth / 2 - 19, 40, { align: "center" });
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#7988F6");
  // Establecer fuente en negrita
  doc.text("DE EXCLUSIÓN", pageWidth / 2 + 19, 40, { align: "center" });
  doc.setFont("helvetica", "normal");
  doc.setTextColor("#394F65");
  doc.setFontSize(10);
  doc.text(
    ` Entre ${" " + detailCompany?.name} en adelante el “GIMNASIO/EQUIPO”, y${
      " " + userDetail?.full_name
    }, identificado con${
      " " + userDetail?.document
    } , en adelante el “USUARIO/ATLETA”, se celebra el presente Contrato de Exclusión de Responsabilidad y manejo de datos , sujeto a las siguientes cláusulas:
      
    CLAUSULA PRIMERA - Aceptación de los Riesgos El USUARIO/ATLETA las actividades físicas, deportivas y de entrenamiento que realiza en el GIMNASIO/EQUIPO conllevan riesgos inherentes de lesiones, incluyendo, pero sin limitarse a, esguinces, fracturas, desgarros musculares, problemas cardiacos, u otros daños físicos o incluso la muerte.

      CLAUSULA SEGUNDA - Asunción Voluntaria del Riesgo El USUARIO/ATLETA manifiesta que realiza las actividades de manera voluntaria, consciente y bajo su propia responsabilidad, eximiendo al GIMNASIO/EQUIPO de cualquier responsabilidad por lesiones o daños físicos que puedan ocurrir durante las actividades físicas, entrenamientos, competencias, o el uso de las instalaciones. El USUARIO/ATLETA reconoce que tiene conocimiento de su estado de salud y aptitud física para participar en dichas actividades.

      CLAUSULA TERCERA - Exoneración de Responsabilidad El USUARIO/ATLETA exonera de manera expresa y definitiva al GIMNASIO/EQUIPO, a sus entrenadores, personal administrativo, colaboradores y afiliados, de cualquier tipo de reclamación, demanda, o acción legal por daños, perjuicios, lesiones o fallecimiento que puedan derivarse de su participación en las actividades, salvo en los casos de dolo o culpa grave del  GIMNASIO/EQUIPO.

      CLAUSULA CUARTA - Declaración sobre el Uso de Equipos e Instalaciones El USUARIO/ATLETA reconoce que ha recibido las indicaciones y recomendaciones necesarias para el uso adecuado del equipo e instalaciones y se compromete a seguirlas estrictamente. Asimismo, se compromete a comunicar al GIMNASIO/EQUIPO cualquier situación que represente un riesgo para su salud o integridad física.

      CLAUSULA QUINTA -Cláusula de Indemnidad El USUARIO/ATLETA se obliga a mantener indemne al GIMNASIO/EQUIPO frente a cualquier reclamación de terceros o gasto judicial que pueda derivarse de daños ocasionados por la conducta del USUARIO/ATLETA dentro de las instalaciones o durante la práctica de las actividades.

      CLAUSULA SEXTA - Renuncia a Reclamaciones Futuras El USUARIO/ATLETA renuncia expresamente a cualquier tipo de reclamación futura relacionada con las actividades realizadas en el GIMNASIO/EQUIPO, salvo que estas deriven de acciones dolosas o negligencia grave por parte del GIMNASIO/EQUIPO.

      CLAUSULA SÉPTIMA - Vigencia y Alcance El presente contrato entra en vigencia desde el momento de su firma y se mantendrá vigente mientras el USUARIO/ATLETA participe en las actividades del GIMNASIO/EQUIPO o haga uso de sus instalaciones, sin perjuicio de las condiciones de renovación o terminación establecidas por las partes.

      CLAUSULA -  Aceptación del Contrato El USUARIO/ATLETA declara que ha leído y entendido la totalidad del presente contrato, que sus términos le han sido explicados a satisfacción y que firma libre y voluntariamente, sin ningún tipo de coacción o presión externa.`,
    20,
    55,
    { align: "left", maxWidth: "170" }
  );

  const buttonWidth = 60;
  const buttonHeight = 10;
  const buttonX = (pageWidth - buttonWidth) / 2; // Centrado horizontalmente
  const buttonY = 245; // Ajusta la posición vertical aquí

  // Dibuja el botón como un rectángulo con bordes redondeados

  doc.setFont("helvetica", "normal");
  doc.text(`${userDetail?.full_name}`, pageWidth / 2, 265, {
    align: "center",
  });

  if (userDetail?.document.length > 0) {
    doc.text(`cc ${userDetail?.document}`, pageWidth / 2, 270, {
      align: "center",
    });
  }

  doc.setFont("helvetica");

  doc.setFontSize(15);
  doc.setTextColor("#FFFFFF");
  doc.setFont("helvetica", "normal");
  doc.text("www.app.elevva.com.co", pageWidth / 2, 290, { align: "center" });

  doc.setFillColor("#3E5060"); // Color de fondo del botón
  doc.roundedRect(buttonX, buttonY, buttonWidth, buttonHeight, 5, 5, "F");

  // Agrega el texto centrado dentro del botón
  doc.setFont("helvetica", "bold"); // Fuente en negrita
  doc.setFontSize(12);
  doc.setTextColor("#FFFFFF"); // Color de texto blanco
  doc.text("ACEPTO", pageWidth / 2, buttonY + buttonHeight / 1.6, {
    align: "center",
  });
  doc.html("string");

  // Descargar el PDF
  doc.save("documento.pdf");
};

const generatePdfTriage = async () => {
  // Referencia al div que queremos convertir en PDF
  const preguntas = [
    "¿Algún médico le ha dicho que tiene problemas del corazón y que sólo debe hacer actividades físicas recomendadas por un médico?",
    "¿Tiene dolor en el pecho cuando hace alguna actividad física?",
    "¿En el último mes, ¿ha tenido dolor en el pecho cuando no estaba haciendo actividades físicas?",
    "¿Pierde el equilibrio por mareos, o ha perdido alguna vez el conocimiento?",
    "¿Tiene problema en algún hueso o articulación que pueda ser agravado por un cambio en su actividad física?",
    "¿Está tomando medicamentos recetados por el médico para la presión arterial o para el corazón (por ejemplo, pastillas diuréticas)?",
    "¿Sabe de cualquier otra razón en contra de que ejercite?",
  ];
  let doc = new jsPDF("p", "mm", "a4");
  let yOffset = 140;
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const logoUrl = "/assets/logo_normal_128.png"; // Ruta del logo en la carpeta pública
  const logoImage = await fetch(logoUrl).then((res) => res.blob());
  const logoBase64 = await convertToBase64(logoImage);

  doc.setFillColor(105, 130, 255);

  // Define the path for the curve
  doc.moveTo(0, 260); // Punto inicial
  doc.curveTo(50, 290, 160, 290, 210, 260); // Control points ajustados
  doc.lineTo(210, 297); // Extender hasta el borde inferior
  doc.lineTo(0, 297); // Completar el camino // Complete the path

  // Fill the curve
  doc.fill();

  // Agregar el logo al PDF
  doc.addImage(logoBase64, "PNG", pageWidth / 2 - 12, 15, 25, 10, {
    align: "center",
  });

  doc.setTextColor("#394F65");

  doc.text("TRIAGE / ", pageWidth / 2 - 30, 40, { align: "center" });
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#7988F6");
  // Establecer fuente en negrita
  doc.text("ESTADO DEL ATLETA", pageWidth / 2 + 15, 40, { align: "center" });

  if (imageBase64User) {
    doc.addImage(imageBase64User, "jpg", pageWidth / 2 - 25, 50, 50, 50, {
      align: "center",
    });
  }

  doc.setFont("helvetica", "normal");
  doc.setTextColor("#394F65");
  doc.setFont("helvetica", "normal");

  doc.text(`${userDetail?.full_name}`, pageWidth / 2, 110, {
    align: "center",
  });
  doc.text(`cc ${userDetail?.document}`, pageWidth / 2, 115, {
    align: "center",
  });

  doc.setFontSize(13);
  preguntas.forEach((pregunta, index) => {
    doc.setTextColor("#394F65");
    doc.text(pregunta, 20, yOffset, { align: "left", maxWidth: "150" });
    if (index === 0) {
      if (userTriage?.health_safe_heart) {
        doc.setFillColor(50, 100, 150); // Color relleno
        doc.roundedRect(180, yOffset - 5, 15, 8, 3, 3, "F");
        doc.setTextColor(255, 255, 255); // Color del texto
        doc.text("SI", 187.5, yOffset + 0.5, null, null, "center");
      } else {
        doc.setFillColor(255, 255, 255);
        doc.setDrawColor(50, 100, 150);
        doc.roundedRect(180, yOffset - 5, 15, 8, 3, 3, "FD");
        doc.setTextColor(0, 0, 0);
        doc.text("NO", 187.5, yOffset + 0.5, null, null, "center");
      }
    } else if (index === 1) {
      if (userTriage?.health_safe_chest) {
        doc.setFillColor(50, 100, 150); // Color relleno
        doc.roundedRect(180, yOffset - 5, 15, 8, 3, 3, "F");
        doc.setTextColor(255, 255, 255); // Color del texto
        doc.text("SI", 187.5, yOffset + 0.5, null, null, "center");
      } else {
        doc.setFillColor(255, 255, 255);
        doc.setDrawColor(50, 100, 150);
        doc.roundedRect(180, yOffset - 5, 15, 8, 3, 3, "FD");
        doc.setTextColor(0, 0, 0);
        doc.text("NO", 187.5, yOffset + 0.5, null, null, "center");
      }
    } else if (index === 2) {
      if (userTriage?.health_safe_chest_last) {
        doc.setFillColor(50, 100, 150); // Color relleno
        doc.roundedRect(180, yOffset - 5, 15, 8, 3, 3, "F");
        doc.setTextColor(255, 255, 255); // Color del texto
        doc.text("SI", 187.5, yOffset + 0.5, null, null, "center");
      } else {
        doc.setFillColor(255, 255, 255);
        doc.setDrawColor(50, 100, 150);
        doc.roundedRect(180, yOffset - 5, 15, 8, 3, 3, "FD");
        doc.setTextColor(0, 0, 0);
        doc.text("NO", 187.5, yOffset + 0.5, null, null, "center");
      }
    } else if (index === 3) {
      if (userTriage?.health_safe_balance) {
        doc.setFillColor(50, 100, 150); // Color relleno
        doc.roundedRect(180, yOffset - 5, 15, 8, 3, 3, "F");
        doc.setTextColor(255, 255, 255); // Color del texto
        doc.text("SI", 187.5, yOffset + 0.5, null, null, "center");
      } else {
        doc.setFillColor(255, 255, 255);
        doc.setDrawColor(50, 100, 150);
        doc.roundedRect(180, yOffset - 5, 15, 8, 3, 3, "FD");
        doc.setTextColor(0, 0, 0);
        doc.text("NO", 187.5, yOffset + 0.5, null, null, "center");
      }
    } else if (index === 4) {
      if (userTriage?.health_safe_bones) {
        doc.setFillColor(50, 100, 150); // Color relleno
        doc.roundedRect(180, yOffset - 5, 15, 8, 3, 3, "F");
        doc.setTextColor(255, 255, 255); // Color del texto
        doc.text("SI", 187.5, yOffset + 0.5, null, null, "center");
      } else {
        doc.setFillColor(255, 255, 255);
        doc.setDrawColor(50, 100, 150);
        doc.roundedRect(180, yOffset - 5, 15, 8, 3, 3, "FD");
        doc.setTextColor(0, 0, 0);
        doc.text("NO", 187.5, yOffset + 0.5, null, null, "center");
      }
    } else if (index === 5) {
      if (userTriage?.health_safe_medicated) {
        doc.setFillColor(50, 100, 150); // Color relleno
        doc.roundedRect(180, yOffset - 5, 15, 8, 3, 3, "F");
        doc.setTextColor(255, 255, 255); // Color del texto
        doc.text("SI", 187.5, yOffset + 0.5, null, null, "center");
      } else {
        doc.setFillColor(255, 255, 255);
        doc.setDrawColor(50, 100, 150);
        doc.roundedRect(180, yOffset - 5, 15, 8, 3, 3, "FD");
        doc.setTextColor(0, 0, 0);
        doc.text("NO", 187.5, yOffset + 0.5, null, null, "center");
      }
    } else {
      if (userTriage?.health_safe_other) {
        doc.setFillColor(50, 100, 150); // Color relleno
        doc.roundedRect(180, yOffset - 5, 15, 8, 3, 3, "F");
        doc.setTextColor(255, 255, 255); // Color del texto
        doc.text("SI", 167.5, yOffset, null, null, "center");
      } else {
        doc.setFillColor(255, 255, 255);
        doc.setDrawColor(50, 100, 150);
        doc.roundedRect(180, yOffset - 5, 15, 8, 3, 3, "FD");
        doc.setTextColor(0, 0, 0);
        doc.text("NO", 187.5, yOffset + 0.5, null, null, "center");
      }
    }
    yOffset += 15; // Espaciado entre preguntas
  });
  doc.setFontSize(15);
  doc.setTextColor("#FFFFFF");
  doc.setFont("helvetica", "normal");
  doc.text("www.app.elevva.com.co", pageWidth / 2, 290, { align: "center" });

  doc.html("string");

  // Descargar el PDF
  doc.save("documento.pdf");
};
return (
  <div className="h-100 p-3">
    {modal}
    {modalFilters}
    {modalNewUser}
    {modalError}
    {userDetail ? (
      stepUser === 1 ? (
        <div
          className="w-100 h-100 d-flex justify-content-center flex-column"
          style={{ padding: "2% 5%" }}
        >
          <div
            className="w-100 relative d-flex   align-items-center mb-3"
            style={{ justifyContent: "space-between" }}
          >
            <div>Perfil de usuario</div>{" "}
            <div
              className="mt-1"
              style={{
                position: "relative",

                fontWeight: "bold",
                fontSize: "1.5rem",
                color: " #9DACBD",
                cursor: "pointer",
              }}
              onClick={() => setUserDetail(null)}
            >
              <i className="bi bi-x-circle"></i>
            </div>
          </div>
          <div className=" w-100 d-flex justify-content-center ">
            <UserDetailComponent
              id={userDetail.user_id}
              handleStepUser={(e) => setStepUser(e)}
            />
          </div>
        </div>
      ) : stepUser === 2 ? (
        <div className="d-flex   align-items-center flex-column p-3">
          <div
            style={{ justifyContent: "flex-start", marginRight: "auto" }}
            className="d-flex   align-items-center "
          >
            <div>
              <ButtonArrow
                classes=" btnBackPr me-1"
                colorBack={`var(--elevvaArrowColor)`}
                handleClick={() => setStepUser(1)}
              />
            </div>
            <span className="ms-1" style={{ color: "#395056" }}>
              Triage
            </span>
          </div>
          <PerfectScrollbar
            className="ps-5"
            style={{ maxHeight: "calc(100vh - 27rem)" }}
          >
            <div className="w-100 d-flex flex-column gap-3 px-4 mt-4">
              <div className="d-flex w-100 align-items-center gap-2">
                <div style={{ width: "87%" }}>
                  {" "}
                  <span
                    style={{
                      color: "#394F65",
                      fontSize: "calc(.45rem + .45vw)",
                    }}
                  >
                    ¿Algún médico le ha dicho que tiene problemas del corazón y
                    que sólo debe hacer actividades físicas recomendadas por un
                    médico?
                  </span>
                </div>
                <div style={{ width: "13%" }}>
                  <button
                    style={{
                      width: "100%",
                      fontSize: "calc(.45rem + .45vw)",
                    }}
                    className={`d-flex ${
                      userTriage?.health_safe_heart
                        ? "add_location "
                        : "add_location_negation "
                    }  py-1`}
                  >
                    {userTriage?.health_safe_heart ? "SI" : "NO"}
                  </button>
                </div>
              </div>
              <div className="d-flex w-100 align-items-center gap-2">
                <div style={{ width: "87%" }}>
                  {" "}
                  <span
                    style={{
                      color: "#394F65",
                      fontSize: "calc(.45rem + .45vw)",
                    }}
                  >
                    ¿Tiene dolor en el pecho cuando hace alguna actividad
                    física?
                  </span>
                </div>
                <div style={{ width: "13%" }}>
                  <button
                    style={{
                      width: "100%",
                      fontSize: "calc(.45rem + .45vw)",
                    }}
                    className={`d-flex ${
                      userTriage?.health_safe_chest
                        ? "add_location "
                        : "add_location_negation "
                    }  py-1`}
                  >
                    {userTriage?.health_safe_chest ? "SI" : "NO"}
                  </button>
                </div>
              </div>
              <div className="d-flex w-100 align-items-center gap-2">
                <div style={{ width: "87%" }}>
                  {" "}
                  <span
                    style={{
                      color: "#394F65",
                      fontSize: "calc(.45rem + .45vw)",
                    }}
                  >
                    ¿En el último mes, ¿ha tenido dolor en el pecho cuando no
                    estaba haciendo actividades físicas?
                  </span>
                </div>
                <div style={{ width: "13%" }}>
                  <button
                    style={{
                      width: "100%",
                      fontSize: "calc(.45rem + .45vw)",
                    }}
                    className={`d-flex ${
                      userTriage?.health_safe_chest_last
                        ? " add_location "
                        : " add_location_negation "
                    }  py-1`}
                  >
                    {userTriage?.health_safe_chest_last ? "SI" : "NO"}
                  </button>
                </div>
              </div>
              <div className="d-flex w-100 align-items-center gap-2">
                <div style={{ width: "87%" }}>
                  {" "}
                  <span
                    style={{
                      color: "#394F65",
                      fontSize: "calc(.45rem + .45vw)",
                    }}
                  >
                    ¿Pierde el equilibrio por mareos, o ha perdido alguna vez el
                    conocimiento?
                  </span>
                </div>
                <div style={{ width: "13%" }}>
                  <button
                    style={{
                      width: "100%",
                      fontSize: "calc(.45rem + .45vw)",
                    }}
                    className={`d-flex ${
                      userTriage?.health_safe_balance
                        ? " add_location "
                        : " add_location_negation "
                    }  py-1`}
                  >
                    {userTriage?.health_safe_balance ? "SI" : "NO"}
                  </button>
                </div>
              </div>
              <div className="d-flex w-100 align-items-center gap-2">
                <div style={{ width: "87%" }}>
                  {" "}
                  <span
                    style={{
                      color: "#394F65",
                      fontSize: "calc(.45rem + .45vw)",
                    }}
                  >
                    ¿Tiene problema en algún hueso o articulación que pueda ser
                    agravado por un cambio en su actividad física?
                  </span>
                </div>
                <div style={{ width: "13%" }}>
                  <button
                    style={{
                      width: "100%",
                      fontSize: "calc(.45rem + .45vw)",
                    }}
                    className={`d-flex ${
                      userTriage?.health_safe_bones
                        ? " add_location "
                        : " add_location_negation "
                    }  py-1`}
                  >
                    {userTriage?.health_safe_bones ? "SI" : "NO"}
                  </button>
                </div>
              </div>
              <div className="d-flex w-100 align-items-center gap-2">
                <div style={{ width: "87%" }}>
                  {" "}
                  <span
                    style={{
                      color: "#394F65",
                      fontSize: "calc(.45rem + .45vw)",
                    }}
                  >
                    ¿Está tomando medicamentos recetados por el médico para la
                    presión arterial o para el corazón (por ejemplo, pastillas
                    diuréticas)?
                  </span>
                </div>
                <div style={{ width: "13%" }}>
                  <button
                    style={{
                      width: "100%",
                      fontSize: "calc(.45rem + .45vw)",
                    }}
                    className={`d-flex ${
                      userTriage?.health_safe_medicated
                        ? " add_location "
                        : " add_location_negation "
                    }  py-1`}
                  >
                    {userTriage?.health_safe_medicated ? "SI" : "NO"}
                  </button>
                </div>
              </div>
              <div className="d-flex w-100 align-items-center gap-2">
                <div style={{ width: "87%" }}>
                  {" "}
                  <span
                    style={{
                      color: "#394F65",
                      fontSize: "calc(.45rem + .45vw)",
                    }}
                  >
                    ¿Sabe de cualquier otra razón en contra de que ejercite?
                  </span>
                </div>
                <div style={{ width: "13%" }}>
                  <button
                    style={{
                      width: "100%",
                      fontSize: "calc(.45rem + .45vw)",
                    }}
                    className={`d-flex ${
                      userTriage?.health_safe_other
                        ? " add_location "
                        : " add_location_negation "
                    }  py-1`}
                  >
                    {userTriage?.health_safe_other ? "SI" : "NO"}
                  </button>
                </div>
              </div>
            </div>
          </PerfectScrollbar>
          <div className="d-flex w-100 mt-4">
            {" "}
            <button
              style={{
                marginRight: "4%",
                fontSize: "calc(.45rem + .45vw)",
                marginLeft: "auto",
              }}
              className="  d-flex add_location px-5 py-1"
              onClick={generatePdfTriage}
            >
              Descargar
            </button>
          </div>
        </div>
      ) : (
        <div className="d-flex   align-items-center flex-column p-3">
          <div
            style={{ justifyContent: "flex-start", marginRight: "auto" }}
            className="d-flex   align-items-center "
          >
            <div>
              <ButtonArrow
                classes=" btnBackPr me-1"
                colorBack={`var(--elevvaArrowColor)`}
                handleClick={() => setStepUser(1)}
              />
            </div>
            <span className="ms-1" style={{ color: "#395056" }}>
              Contrato de exclusión
            </span>
          </div>
          <PerfectScrollbar
            className="ps-5"
            style={{ maxHeight: "calc(100vh - 28rem)" }}
          >
            <div>
              <span
                style={{ color: "#9DACBD", fontSize: "calc(.45rem + .45vw)" }}
              >
                Entre
                <span style={{ textTransform: "uppercase" }}>
                  {" " + detailCompany?.name}
                </span>
                en adelante el “GIMNASIO/EQUIPO”, y{" " + userDetail?.full_name}
                , identificado con {" " + userDetail?.document}, en adelante el
                “USUARIO/ATLETA”, se celebra el presente Contrato de Exclusión
                de Responsabilidad y manejo de datos , sujeto a las siguientes
                cláusulas: CLAUSULA PRIMERA - Aceptación de los Riesgos El
                USUARIO/ATLETA declara que comprende y acepta que las
                actividades físicas, deportivas y de entrenamiento que realiza
                en el GIMNASIO/EQUIPO conllevan riesgos inherentes de lesiones,
                incluyendo, pero sin limitarse a, esguinces, fracturas,
                desgarros musculares, problemas cardiacos, u otros daños físicos
                o incluso la muerte. CLAUSULA SEGUNDA - Asunción Voluntaria del
                Riesgo El USUARIO/ATLETA manifiesta que realiza las actividades
                de manera voluntaria, consciente y bajo su propia
                responsabilidad, eximiendo al GIMNASIO/EQUIPO de cualquier
                responsabilidad por lesiones o daños físicos que puedan ocurrir
                durante las actividades físicas, entrenamientos, competencias, o
                el uso de las instalaciones. El USUARIO/ATLETA reconoce que
                tiene conocimiento de su estado de salud y aptitud física para
                participar en dichas actividades. CLAUSULA TERCERA - Exoneración
                de Responsabilidad El USUARIO/ATLETA exonera de manera expresa y
                definitiva al GIMNASIO/EQUIPO, a sus entrenadores, personal
                administrativo, colaboradores y afiliados, de cualquier tipo de
                reclamación, demanda, o acción legal por daños, perjuicios,
                lesiones o fallecimiento que puedan derivarse de su
                participación en las actividades, salvo en los casos de dolo o
                culpa grave del GIMNASIO/EQUIPO. CLAUSULA CUARTA - Declaración
                sobre el Uso de Equipos e Instalaciones El USUARIO/ATLETA
                reconoce que ha recibido las indicaciones y recomendaciones
                necesarias para el uso adecuado del equipo e instalaciones y se
                compromete a seguirlas estrictamente. Asimismo, se compromete a
                comunicar al GIMNASIO/EQUIPO cualquier situación que represente
                un riesgo para su salud o integridad física. CLAUSULA QUINTA -
                Cláusula de Indemnidad El USUARIO/ATLETA se obliga a mantener
                indemne al GIMNASIO/EQUIPO frente a cualquier reclamación de
                terceros o gasto judicial que pueda derivarse de daños
                ocasionados por la conducta del USUARIO/ATLETA dentro de las
                instalaciones o durante la práctica de las actividades. CLAUSULA
                SEXTA - Renuncia a Reclamaciones Futuras El USUARIO/ATLETA
                renuncia expresamente a cualquier tipo de reclamación futura
                relacionada con las actividades realizadas en el
                GIMNASIO/EQUIPO, salvo que estas deriven de acciones dolosas o
                negligencia grave por parte del GIMNASIO/EQUIPO. CLAUSULA
                SÉPTIMA - Vigencia y Alcance El presente contrato entra en
                vigencia desde el momento de su firma y se mantendrá vigente
                mientras el USUARIO/ATLETA participe en las actividades del
                GIMNASIO/EQUIPO o haga uso de sus instalaciones, sin perjuicio
                de las condiciones de renovación o terminación establecidas por
                las partes. CLAUSULA - Aceptación del Contrato El USUARIO/ATLETA
                declara que ha leído y entendido la totalidad del presente
                contrato, que sus términos le han sido explicados a satisfacción
                y que firma libre y voluntariamente, sin ningún tipo de coacción
                o presión externa.
              </span>
            </div>
          </PerfectScrollbar>
          <div className="d-flex w-100 mt-4">
            {" "}
            <button
              style={{
                marginRight: "2%",
                fontSize: "calc(.45rem + .45vw)",
                marginLeft: "auto",
              }}
              className="  d-flex add_location px-4 py-1"
              onClick={generatePdf}
            >
              Descargar
            </button>
          </div>
        </div>
      )
    ) : (
      <div>
        <div
          className={`d-flex justify-content-center  align-items-center mx-auto ${
            laptopMax1350.matches ? "my-3 px-1" : "my-3 px-3"
          } `}
        >
          <div
            style={{
              lineHeight: "1",
              width: laptop1600.matches ? "30%" : "20%",
            }}
          >
            <span
              style={{
                fontSize: laptop1600.matches
                  ? "calc(.5rem + .5vw)"
                  : "calc(.4rem + .4vw)",
              }}
            >
              Administración de usuarios
            </span>
          </div>
          <div
            className={`d-flex  align-items-center w-100 mx-auto ${
              laptop1600.matches ? " gap-4 " : " gap-2 "
            }  justify-content-end`}
          >
            {" "}
            <div className="cursor-pointer" onClick={handlerTags}>
              <TagIcon />
            </div>
            <div className="cursor-pointer">
              <button
                style={{ padding: "0.4rem" }}
                className="  d-flex add_location w-100"
                onClick={getUsers}
              >
                {" "}
                <ReloadIcon />
              </button>
            </div>
            <button
              style={{ padding: "0.6rem 1rem", lineHeight: "1" }}
              className="  d-flex add_location "
              onClick={handleNewUser}
            >
              Preregistrar Usuario
            </button>
            <button
              style={{ padding: "0.6rem 1rem", lineHeight: "1" }}
              className="  d-flex add_location  "
              onClick={handleDeleteUser}
            >
              Eliminar usuario
            </button>
            <button
              style={{ padding: "0.6rem 1rem", lineHeight: "1" }}
              className="  d-flex add_location "
              onClick={handleDownloadExcel}
            >
              Descargar Base de datos{" "}
            </button>
          </div>
        </div>
        <div className="d-flex  gap-2 pe-3 align-items-center ">
          <div style={{ position: "relative", marginLeft: "auto" }}>
            {" "}
            <input
              placeholder="Buscar usuario"
              type="search"
              className="searchUsers "
              style={{
                border: "1px solid var(--elevvaTextSubtitle)",
                borderRadius: "20px",
                fontSize: "calc(.33rem + .32vw)",
                padding: "0.4rem 1rem",
              }}
              onChange={searchUsers}
            ></input>
            <span className="search-icon-admin">
              <SearchIconAlone fontSize={10} />
            </span>
          </div>
          <div className="d-flex align-items-center me-1">
            <FiltersTable
              style={{ width: "100%", padding: "2%" }}
              className="cursor-pointer"
              onClick={filtersUser}
            />
          </div>
        </div>
        <div className={laptopMax1350.matches ? "p-1" : "p-3"}>
          <div style={{ width: "100%" }}>
            <PerfectScrollbar
              style={{
                maxHeight: "calc(75vh - 15rem)",
                overflowY: "scroll",
              }}
            >
              <table style={{ width: "100%" }}>
                <thead
                  className="size-table-users"
                  style={{
                    width: "100%",
                    overflow: "scroll",
                    position: "sticky",
                    top: "0",
                    backgroundColor: "white",
                  }}
                >
                  <tr>
                    <th
                      style={{
                        fontWeight: "normal",
                        width: "25%",
                      }}
                    >
                      <div className="d-flex gap-2 align-items-center ">
                        {" "}
                        <label className="custom-checkbox">
                          <input
                            type="checkbox"
                            name="userAll"
                            onClick={(e) => {
                              let checkedAll = e.target.checked;
                              if (checkedAll) {
                                setUserSelected(
                                  users.map((user) => user.user_id)
                                );
                              } else {
                                setUserSelected([]);
                              }
                            }}
                          />
                          <span className="checkmark"></span>
                        </label>{" "}
                        <span className="size-table-users">Nombre</span>{" "}
                      </div>
                    </th>
                    <th
                      style={{
                        fontWeight: "normal",
                        width: "25%",
                      }}
                    >
                      Correo Electrónico
                    </th>
                    <th
                      style={{
                        fontWeight: "normal",
                        width: "20%",
                      }}
                    >
                      Etiqueta
                    </th>
                    <th
                      style={{
                        fontWeight: "normal",
                        width: "20%",
                      }}
                    >
                      Membresía
                    </th>
                    <th
                      style={{
                        fontWeight: "normal",
                        width: "10%",
                      }}
                    >
                      Vencimiento
                    </th>
                  </tr>
                </thead>
                <tbody className="w-100">
                  {users.length > 0 &&
                    users.map((user) => (
                      <tr
                        key={user.user_id}
                        className="size-table-users"
                        style={{
                          borderBottom: "1px solid #A8C2D7",
                          color: "#394F65",
                        }}
                      >
                        <td
                          style={{ width: "25%" }}
                          className="flex gap-2 align-items-center justify-content-center py-3"
                        >
                          <div className="d-flex gap-2 align-items-center ">
                            <label className="custom-checkbox">
                              <input
                                type="checkbox"
                                name="user"
                                checked={userSelected.includes(user.user_id)}
                                onClick={() =>
                                  setUserSelected((prev) => {
                                    if (prev.includes(user.user_id)) {
                                      return prev.filter(
                                        (item) => item !== user.user_id
                                      );
                                    }
                                    return [...prev, user.user_id];
                                  })
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                            <div className="size-table-users">
                              {user.full_name}
                            </div>
                          </div>
                        </td>
                        <td style={{ width: "25%" }}>{user.email}</td>
                        <td style={{ width: "20%" }}>
                          {user.tags.length > 0
                            ? user.tags.map((e) => e.name).join(", ")
                            : "----"}
                        </td>
                        <td style={{ width: "20%" }}>
                          <div className="d-flex align-items-center gap-2">
                            <div
                              onClick={() => editMembershipClient(user.user_id)}
                            >
                              {" "}
                              <EditIcon
                                className="cursor-pointer"
                                width={"20px"}
                                height={"20px"}
                              />
                            </div>
                            <div style={{ lineHeight: "1" }}>
                              <span className="size-table-users">
                                {user.membership_name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td style={{ width: "5%" }}>
                          {user.end_date
                            ? moment(user.end_date)?.format("DD-MM-YY")
                            : "---"}
                        </td>
                        <td style={{ width: "5%", textAlign: "right" }}>
                          <button
                            onClick={() => setUserDetail(user)}
                            className="pe-3"
                            style={{
                              border: "none",
                              background: "transparent",
                            }}
                          >
                            <SearchInvidual />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </PerfectScrollbar>
            <div className="d-flex justify-content-end align-items-center gap-3 mt-2">
              <span>
                {page * 10 - 10 === 0 || count >= 10
                  ? page === 1
                    ? 1
                    : count
                  : page * 10 - 10}
                - {page * 10 >= count ? count : page * 10} de {count}
              </span>
              <div
                onClick={count <= 10 ? null : () => changePage("previous")}
                className="cursor-pointer"
              >
                <ArrowLeft />
              </div>
              <div
                onClick={page * 10 >= count ? null : () => changePage("next")}
                className="cursor-pointer"
              >
                <ArrowRight />
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);
};

export default UserAdmin;
