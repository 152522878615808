import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import LogoSupremacyBig from "../../assets/assets/elevva-mora_big.png";
import {
  instance,
  laptop1350,
  laptop1600,
  laptopMax1350,
} from "../../utils/utils";
import ComponentCompany from "./component-company";
import TrainingPlanForm from "./training-plan-form";
import UserAdmin from "./user-admin";

const Admin = (props) => {
  const [detailCompany, setDetailCompany] = useState(null);
  const [modal, setModal] = useState(null);
  const [loading, setLoading] = useState(false);
  console.log(detailCompany, "detailCompany");

  function getCompanyDetail() {
    setLoading(true);
    instance
      .get(`memberships/corp/company`)
      .then((res) => res.data)
      .then((data) => {
        setDetailCompany(data);

        setLoading(false);
      })
      .catch((err) => console.error(err));
  }
  useEffect(() => {
    getCompanyDetail();
  }, []);

  function showMembersPreRegister() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };

    instance
      .get(`memberships/corp/company/members/preregisters`)
      .then((res) => res.data)
      .then((data) => {
        console.log(data, "data");
        setModal(
          <div
            className="popUpServices justify-content-center align-items-center"
            style={styleModal}
          >
            <div
              className="modalInfoContenido"
              style={{
                background: "white",
                borderRadius: "20px",
                boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
                padding: "4% 10%",
              }}
            >
              <div
                className=""
                style={{
                  position: "absolute",
                  top: "5%",
                  right: "5%",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  color: " #9DACBD",
                  cursor: "pointer",
                }}
                onClick={() => setModal(null)}
              >
                <i className="bi bi-x-circle"></i>
              </div>
              <div
                className=" mb-3 py-3"
                style={{
                  lineHeight: "100%",
                  textAlign: "center",

                  color: "var(--elevvabutonLogin_User)",
                }}
              >
                <strong>Pendientes por crear:</strong>
              </div>
              {data.length > 0 ? (
                <PerfectScrollbar
                  className="px-3"
                  style={{ maxHeight: "15rem" }}
                >
                  <div className="table-container">
                    <table className="custom-table">
                      <thead>
                        <tr>
                          <th>Correo electrónico</th>
                          <th>Membresía</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((row, index) => (
                          <tr key={index}>
                            <td>{row.email}</td>
                            <td>{row.membership}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </PerfectScrollbar>
              ) : (
                "No existen usuarios"
              )}
            </div>
          </div>
        );
      })
      .catch((err) => console.error(err));
  }

  return (
    <div style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
      {modal}
      <div
        className="d-flex position-relative"
        style={{
          height: "60px",
          background: "var(--backgroundOnboarding)",
          columnGap: "2rem",
        }}
      >
        <div className="logo-supremacy">
          <img style={{ width: "6rem" }} src={LogoSupremacyBig}></img>
        </div>
      </div>
      <div
        style={{
          flexGrow: "1",
          overflow: "auto",
          columnGap: "calc(.5rem + .5vw)",
        }}
        className="d-flex py-2 px-3"
      >
        <div
          style={{
            width: "18%",
            border: "1px solid #B6C1CE",
            borderRadius: "10px",
            height: "100%",
            padding: "1rem 0.6rem",
          }}
        >
          <ComponentCompany />
        </div>
        <div
          className="d-flex gap-3 flex-column"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <div className="d-flex gap-3 w-100" style={{ height: "25%" }}>
            <div
              className="d-flex  align-items-center justify-content-center flex-column"
              style={{
                width: "25%",
                border: "1px solid #B6C1CE",
                borderRadius: "10px",
                height: "100%",
              }}
            >
              <div className="my-2">
                <span
                  style={{ color: "#394F65", fontSize: "calc(.45rem + .45vw)" }}
                >
                  {detailCompany?.plan_description || "---"}
                </span>
              </div>
              <div
                className="w-100 text-center my-2 "
                style={{
                  background: "#DFDFDF",
                  padding: laptop1600.matches
                    ? "0.8rem 0"
                    : laptopMax1350.matches
                    ? "0.5rem 0"
                    : "0.6rem 0",
                  lineHeight: laptop1350.matches ? "inherit" : "1",
                }}
              >
                <span
                  style={{
                    color: "#394F65",
                    fontSize: "calc(.45rem + .45vw)",
                  }}
                >
                  Seguimiento y data{" "}
                  {detailCompany ? detailCompany.active_users : 0} usuarios
                </span>
              </div>
              <div className="my-2">
                <span
                  style={{ color: "#394F65", fontSize: "calc(.45rem + .45vw)" }}
                >
                  Vencimiento: {detailCompany?.end_plan || "yyyy/mm/dd"}
                </span>
              </div>
            </div>
            <div
              className="d-flex  align-items-center justify-content-center flex-column"
              style={{
                width: "25%",
                border: "1px solid #B6C1CE",
                borderRadius: "10px",
                height: "100%",
              }}
            >
              <div className="my-2">
                <span
                  style={{ color: "#394F65", fontSize: "calc(.45rem + .45vw)" }}
                >
                  Usuarios activos
                </span>
              </div>
              <div
                className="w-100 text-center my-2 py-2"
                style={{ background: "#DFDFDF" }}
              >
                <span
                  style={{
                    color: "#394F65",
                    fontSize: "calc(.6rem + .6vw)",
                    fontWeight: "bold",
                  }}
                >
                  {detailCompany ? detailCompany.active_users : 0}
                </span>
              </div>
              <div className="my-2">
                <span
                  style={{ color: "#ffff", fontSize: "calc(.6rem + .6vw)" }}
                >
                  .
                </span>
              </div>
            </div>
            <div
              className="d-flex  align-items-center justify-content-center flex-column"
              style={{
                width: "25%",
                border: "1px solid #B6C1CE",
                borderRadius: "10px",
                height: "100%",
              }}
            >
              <div className="my-2">
                <span
                  style={{ color: "#394F65", fontSize: "calc(.45rem + .45vw)" }}
                >
                  Pendientes por crear cuenta
                </span>
              </div>
              <div
                className="w-100 text-center my-2 py-2"
                style={{ background: "#DFDFDF" }}
              >
                <span
                  style={{
                    color:
                      detailCompany &&
                      detailCompany.pre_register_members &&
                      detailCompany.pre_register_members > 0
                        ? "red"
                        : "#394F65",
                    fontSize: "calc(.6rem + .6vw)",
                    fontWeight: "bold",
                  }}
                >
                  {detailCompany &&
                  detailCompany.pre_register_members &&
                  detailCompany.pre_register_members > 0
                    ? detailCompany.pre_register_members
                    : "---"}
                </span>
              </div>
              <div className="my-2">
                {" "}
                <button
                  style={{
                    fontSize: "calc(.33rem + .32vw)",
                    marginLeft: "auto",
                  }}
                  className="  d-flex add_location px-4 py-1"
                  onClick={() => {
                    showMembersPreRegister();
                  }}
                >
                  Ver más
                </button>
              </div>
            </div>
            <div
              className="d-flex  align-items-center justify-content-center flex-column"
              style={{
                width: "25%",
                border: "1px solid #B6C1CE",
                borderRadius: "10px",
                height: "100%",
              }}
            >
              <TrainingPlanForm />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              border: "1px solid #B6C1CE",
              borderRadius: "10px",
              height: "75%",
            }}
          >
            <UserAdmin />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Admin;
