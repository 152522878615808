import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { laptopMax1350 } from "./utils";
import { ReactComponent as SearchInvidual } from "../assets/elevva-pro-icons/search-individual-table.svg";
import { ReactComponent as ArrowLeft } from "../assets/elevva-pro-icons/table-arrow-left.svg";
import { ReactComponent as ArrowRight } from "../assets/elevva-pro-icons/table-arrow-right.svg";
import { ReactComponent as EditIcon } from "../assets/elevva-pro-icons/edit-icon.svg";
import { ReactComponent as DeleteTag } from "../assets/elevva-pro-icons/delete-tag.svg";
import { ReactComponent as ReloadIcon } from "../assets/elevva-pro-icons/reloadIcon.svg";

import moment from "moment";

const TableCustom = (props) => {
  const users = props.users;
  const page = props.page;
  const count = props.count;
  const changePage = props.changePage;

  const deleteUser = props.deleteUser;
  const editUser = props.editUser;
  const renewUser = props.renewUser;

  return (
    <div className={laptopMax1350.matches ? "p-1" : "p-3"}>
      <div style={{ width: "100%" }}>
        <PerfectScrollbar
          style={{
            maxHeight: "calc(75vh - 20rem)",
            overflowY: "scroll",
          }}
        >
          <table style={{ width: "100%", backgroundColor: "white" }}>
            <thead
              className="size-table-users"
              style={{
                width: "100%",
                overflow: "scroll",
                position: "sticky",
                top: "0",
                backgroundColor: "white",
              }}
            >
              <tr>
                <th
                  style={{
                    fontWeight: "normal",
                    width: "25%",
                  }}
                >
                  <div className="d-flex gap-2 align-items-center ">
                    {" "}
                    <span className="size-table-users">Nombre</span>{" "}
                  </div>
                </th>
                <th
                  style={{
                    fontWeight: "normal",
                    width: "25%",
                  }}
                >
                  Correo Electrónico
                </th>
                <th
                  style={{
                    fontWeight: "normal",
                    width: "20%",
                  }}
                >
                  plan
                </th>
                <th
                  style={{
                    fontWeight: "normal",
                    width: "20%",
                  }}
                >
                  Accesos<br></br> restantes
                </th>
                <th
                  style={{
                    fontWeight: "normal",
                    width: "10%",
                  }}
                >
                  Vencimiento
                </th>
              </tr>
            </thead>
            <tbody className="w-100">
              {users.length > 0 &&
                users.map((user) => (
                  <tr
                    key={user.user_id}
                    className="size-table-users"
                    style={{
                      borderBottom: "1px solid #A8C2D7",
                      color: "#394F65",
                    }}
                  >
                    <td
                      style={{ width: "25%" }}
                      className="flex gap-2 align-items-center justify-content-center py-3"
                    >
                      <div className="d-flex gap-2 align-items-center ">
                        <div className="size-table-users">
                          {user.customer_name}
                        </div>
                      </div>
                    </td>
                    <td style={{ width: "25%" }}>{user.customer_email}</td>
                    <td style={{ width: "20%" }}>
                      {user.memberships.length > 0
                        ? user.memberships.map((e) => (
                            <>
                              {e.plan_name}({e.service_name}) <br />
                            </>
                          ))
                        : "----"}
                    </td>
                    <td style={{ width: "20%" }}>
                      <div className="d-flex align-items-center gap-2">
                        <div style={{ lineHeight: "1" }}>
                          <span className="size-table-users">
                            {user.memberships.length > 0
                              ? user.memberships.map((e) => (
                                  <>
                                    {e.remaining_quota} <br />
                                  </>
                                ))
                              : "----"}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td style={{ width: "5%" }}>
                      {user.memberships.length > 0
                        ? user.memberships.map((e) => (
                            <>
                              {moment(e.membership_end_date)?.format(
                                "DD-MM-YY"
                              )}{" "}
                              <br />
                            </>
                          ))
                        : "----"}
                    </td>
                    <td style={{ width: "5%", textAlign: "right" }}>
                      <div className="d-flex gap-2">
                        <div className="cursor-pointer">
                          <button
                            style={{ padding: "0.4rem" }}
                            className="  d-flex add_location w-100"
                            onClick={() => renewUser(user)}
                          >
                            {" "}
                            <ReloadIcon />
                          </button>
                        </div>
                        <div>
                          {" "}
                          <EditIcon
                            onClick={() => editUser(user)}
                            className="cursor-pointer"
                            width={"20px"}
                            height={"20px"}
                          />
                        </div>
                        {/* <div>
                          {" "}
                          <DeleteTag
                            onClick={() => deleteUser(user)}
                            className="cursor-pointer"
                            width={"20px"}
                            height={"20px"}
                          />
                        </div> */}
                      </div>
                      {/* <button
                        onClick={() => setUserDetail(user)}
                        className="pe-3"
                        style={{
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <SearchInvidual />
                      </button> */}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </PerfectScrollbar>
        <div className="d-flex justify-content-end align-items-center gap-3 mt-2">
          <span>
            {page * 10 - 10 === 0 || count >= 10
              ? page === 1
                ? 1
                : count
              : page * 10 - 10}
            - {page * 10 >= count ? count : page * 10} de {count}
          </span>
          <div
            onClick={count <= 10 ? null : () => changePage("previous")}
            className="cursor-pointer"
          >
            <ArrowLeft />
          </div>
          <div
            onClick={page * 10 >= count ? null : () => changePage("next")}
            className="cursor-pointer"
          >
            <ArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableCustom;
