import { useEffect, useState } from "react";
import { instance, laptop1600, urlImages } from "../../utils/utils";

const UserDetailComponent = (props) => {
  const id = props.id;
  const handleStepUser = props.handleStepUser;
  const [userDetail, setUserDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  function getUser() {
    instance
      .get(`memberships/corp/company/members/${id}`)
      .then((res) => res.data)
      .then((data) => {
        setUserDetail(data);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }
  useEffect(() => {
    getUser();
  }, [id]);

  const detail_user = [
    { label: "Nombre", value: userDetail?.full_name || "---" },
    { label: "Peso", value: userDetail?.weight || "---" },
    { label: "E-mail", value: userDetail?.email || "---" },
    { label: "Contacto", value: userDetail?.mobile_number || "---" },
    { label: "Fecha de nacimiento", value: userDetail?.birthday || "---" },
    { label: "Pais", value: userDetail?.country || "---" },
    {
      label: "Genero",
      value: userDetail?.gender === 1 ? "Masculino" : "Femenino",
    },
    { label: "Ciudad", value: userDetail?.city || "---" },
    { label: "Estatura", value: userDetail?.height || "---" },
    { label: "Dirección", value: userDetail?.address || "---" },
  ];
  const emergency_user = [
    {
      label: "Nombre",
      value: userDetail?.emergency_contact || "---",
    },
    {
      label: "Contacto",
      value: userDetail?.emergency_contact_phone || "---",
    },
  ];

  return loading ? (
    <div>Cargando...</div>
  ) : (
    <div className="w-100 d-flex gap-3">
      <div className="me-4">
        {" "}
        <img
          src={
            `${urlImages}${userDetail?.profile_pic}` ||
            "/assets/logo-supremacy.png"
          }
          alt="Avatar"
          style={{
            cursor: "pointer",
            width: laptop1600.matches
              ? "calc(8rem + .5vw)"
              : "calc(6rem + .5vw)",
            height: laptop1600.matches
              ? "calc(8rem + .5vw)"
              : "calc(6rem + .5vw)",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      </div>
      <div className="w-100">
        {" "}
        <div
          className="w-100  justify-content-center"
          style={{
            display: "grid",
            gridTemplateColumns: laptop1600.matches
              ? "17rem 17rem"
              : "12rem 12rem",
            gap: "0 4rem",
          }}
        >
          {detail_user.map((item, i) => (
            <div key={i}>
              <label
                style={{ color: "#9DACBD", fontSize: "calc(.45rem + .45vw)" }}
              >
                {item.label}
              </label>
              <div>
                {" "}
                <span
                  style={{
                    color: "#394F65",
                    fontSize: "calc(.45rem + .45vw)",
                  }}
                >
                  <strong>{item.value}</strong>
                </span>
              </div>
            </div>
          ))}
        </div>
        <div
          className="w-100 mt-1 flex justify-content-center"
          style={{
            display: "grid",
            gridTemplateColumns: laptop1600.matches
              ? "17rem 17rem"
              : "12rem 12rem",
            gap: "0 4rem",
          }}
        >
          {" "}
          <label
            style={{
              color: "#394F65",
              fontSize: laptop1600.matches ? "1.3rem" : "1rem",
            }}
          >
            Contacto de emergencia
          </label>{" "}
        </div>
        <div
          className="w-100  justify-content-center"
          style={{
            display: "grid",
            gridTemplateColumns: laptop1600.matches
              ? "17rem 17rem"
              : "12rem 12rem",
            gap: "0 4rem",
          }}
        >
          {emergency_user.map((item, i) => (
            <div key={i}>
              <label
                style={{ color: "#9DACBD", fontSize: "calc(.45rem + .45vw)" }}
              >
                {item.label}
              </label>
              <div>
                {" "}
                <span
                  style={{
                    color: "#394F65",
                    fontSize: "calc(.45rem + .45vw)",
                  }}
                >
                  <strong>{item.value}</strong>
                </span>
              </div>
            </div>
          ))}{" "}
        </div>
        <div
          className="w-100  justify-content-center"
          style={{
            display: "grid",
            gridTemplateColumns: laptop1600.matches
              ? "17rem 17rem"
              : "12rem 12rem",
            gap: "0 4rem",
          }}
        >
          <label
            style={{
              color: "#395056",
              fontSize: laptop1600.matches ? "1.3rem" : "1rem",
            }}
          >
            Documentación
          </label>
        </div>
        <div className="d-flex gap-2 ms-2 mt-2 justify-content-center">
          {" "}
          <button
            style={{ marginRight: "2%", fontSize: "calc(.45rem + .45vw)" }}
            className="  d-flex add_location px-4 py-1"
            onClick={() => handleStepUser(2)}
          >
            Triage
          </button>
          <button
            style={{ marginRight: "2%", fontSize: "calc(.45rem + .45vw)" }}
            className="  d-flex add_location px-4 py-1"
            onClick={() => handleStepUser(3)}
          >
            Contrato de exclusión
          </button>
        </div>
      </div>
    </div>
  );
};
export default UserDetailComponent;
