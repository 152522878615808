import {
  Loadingspinner,
  instance,
  laptop1350,
  laptop1600,
} from "../../utils/utils";
import LogoSupremacyBig from "../../assets/assets/elevva-mora_big.png";
import { SeleccionaMembresia } from "../dashboard/utils";
import { useEffect, useState } from "react";
import { ReactComponent as Campana } from "../../assets/elevva-pro-icons/campana_notification.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import imageDefault from "../../assets/corporative/imageDefault.png";
import Moment from "moment";
import { ReactComponent as ArrowLesion } from "../../assets/corporative/arrow-lesion-corporative.svg";

const Notifications = (props) => {
  const [totalMemberships, settotalMemberships] = useState(null);
  const [MembershipOptionId, setMembershipOptionId] = useState(null);
  const [notifications, setnotifications] = useState([]);
  const [loading, setloading] = useState(true);
  const [Comments, setComments] = useState(null);
  const [commentSelected, setcommentSelected] = useState(null);
  const [IdCustomer, setIdCustomer] = useState(null);
  const [totalCustomers, settotalCustomers] = useState(null);
 
  useEffect(() => {
    if (localStorage.refresh && window.location.pathname == `/notifications`) {
      instance
        .get("app/notifications/unread")
        .then((res) => res.data)
        .then((data) => {
          setloading(false);
          setnotifications(data);
        })
        .catch((err) => console.error(err));
    }

    instance
      .get("memberships/corp/memberships")
      .then((res) => res.data)
      .then((data) => {
        if (data.length == 1) {
          setMembershipOptionId(data[0].id);
        }
        settotalMemberships(data);
      })
      .catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    if (MembershipOptionId) {
      instance
        .get(`memberships/corp/${MembershipOptionId}/members/`)
        .then((res) => res.data)
        .then((data) => {
          settotalCustomers(data);

          // setIdCustomer(data[0].customer);
        })
        .catch((err) => console.error(err));
    }
  }, [MembershipOptionId]);
  useEffect(() => {
    instance
      .get(`customers/dailylog/?customer=${IdCustomer}`)
      .then((res) => res.data)
      .then((data) => {
        setComments(data);
        setcommentSelected(data[0]);
      })
      .catch((err) => console.error(err));
  }, [IdCustomer]);
  function onlyOne(checkbox) {
    var checkboxes = document.getElementsByName("customerList");
    setIdCustomer(checkbox.value);
    checkboxes.forEach((item) => {
      if (item !== checkbox) item.checked = false;
    });
  }

  function changeOptionVideos(valuesearch) {
    if (valuesearch != "") {
      let valueLower = valuesearch.toLowerCase();
      let filterNames = totalCustomers.filter(
        (memb) =>
          memb.last_name.toLowerCase().includes(valueLower) ||
          memb.first_name.toLowerCase().includes(valueLower)
      );
      settotalCustomers(filterNames);
    } else {
      if (MembershipOptionId) {
        instance
          .get(`memberships/corp/${MembershipOptionId}/members/`)
          .then((res) => res.data)
          .then((data) => {
            settotalCustomers(data);

            // setIdCustomer(data[0].customer);
          })
          .catch((err) => console.error(err));
      }
    }
  }

  return (
    <div style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
      <div
        className="d-flex position-relative"
        style={{
          height: "60px",
          background: "var(--backgroundOnboarding)",
          columnGap: "2rem",
        }}
      >
        <SeleccionaMembresia
          styleComponent="input-style-elevva-corporativo"
          textStart="SELECCIONA MEMBRESÍA"
          totalMemberships={totalMemberships && totalMemberships}
          membershipIdOption={(e) => setMembershipOptionId(e)}
        />{" "}
        <div className="logo-supremacy">
          <img style={{ width: "6rem" }} src={LogoSupremacyBig}></img>
          {/* <LogoSupremacy /> */}
        </div>
      </div>
      <div
        style={{
          flexGrow: "1",
          overflow: "auto",
          columnGap: "calc(.5rem + .5vw)",
        }}
        className="d-flex py-2 px-3 flex-column"
      >
        {loading ? (
          <div>
            <Loadingspinner customStyle="grow" size="2rem" />
          </div>
        ) : (
          <div className="w-100 d-flex h-100">
            <div
              className="d-flex"
              style={{
                width: "70%",
                height: "calc(90vh - 60px)",
                padding: "2% 0",
              }}
            >
              <div
                className="d-flex flex-column"
                style={{
                  justifyContent: "center",
                  width: "17%",
                }}
              >
                <span
                  className="mb-2"
                  style={{
                    textAlign: "center",
                    fontSize: "calc(0.6vmin + 0.6vw)",
                  }}
                >
                  Seleccionar cliente
                </span>
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "center",
                    border: "1px solid #9DACBD",
                    padding: "2%",
                    borderRadius: "20px",
                  }}
                >
                  <div>
                    <PerfectScrollbar
                      style={{
                        padding: "0",
                        listStyle: "none",
                        overflowX: "auto",

                        height: "calc(57vmin + 0.5vw)",
                      }}
                    >
                      <input
                        placeholder="Ingresa atleta"
                        type="search"
                        className="searchAtletas py-1 mt-1 mx-1"
                        style={{
                          border: "1px solid var(--elevvaTextSubtitle)",
                          borderRadius: "20px",
                          width: "95%",
                        }}
                        onChange={(e) => changeOptionVideos(e.target.value)}
                      ></input>
                      {totalCustomers &&
                        totalCustomers.map((memb) => (
                          <li
                            className="text-left d-flex my-1"
                            key={"customer" + memb.id}
                          >
                            {" "}
                            <input
                              type="checkbox"
                              id={"customerList" + memb.customer}
                              name="customerList"
                              value={memb.customer}
                              onClick={(e) => onlyOne(e.target)}
                            />{" "}
                            <div
                              className="d-flex w-100"
                              style={{
                                padding: "0.7rem 0",
                                fontSize: "calc(.4rem + .3vw)",
                                whiteSpace: "nowrap",
                                color: "#9DACBD",
                                borderBottom: "1px solid #9DACBD",
                              }}
                            >
                              <div className="d-flex align-items-center">
                                {" "}
                                <img
                                  className="ms-2 me-1"
                                  style={{
                                    objectFit: "cover",
                                    width: laptop1600.matches ? "30px" : "25px",
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                    height: laptop1600.matches
                                      ? "30px"
                                      : "25px",
                                    borderRadius: "50%",
                                  }}
                                  src={
                                    memb.profile_pic
                                      ? memb.profile_pic
                                      : imageDefault
                                  }
                                ></img>{" "}
                              </div>

                              <div
                                className={
                                  laptop1600.matches
                                    ? "d-flex align-items-center"
                                    : ""
                                }
                                style={{ lineHeight: "0.9" }}
                              >
                                <span
                                  style={{
                                    fontSize: "calc(.4rem + .3vw)",
                                    lineHeight: "0.2",
                                  }}
                                >
                                  {memb.first_name}{" "}
                                  {laptop1600.matches ? null : <br></br>}
                                  {memb.last_name}
                                </span>
                              </div>
                            </div>
                          </li>
                        ))}
                    </PerfectScrollbar>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "83%",
                  padding: "0 5%",
                }}
              >
                <div
                  className="title col-12 mb-5 w-100"
                  style={{
                    textAlign: "center",
                    lineHeight: "100%",
                    textAlign: "center",
                    fontSize: "calc(0.6vmin + 0.6vw)",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "calc(0.6vmin + 0.6vw)",
                      color: "#394F65",
                    }}
                  >
                    Bitácora{" "}
                  </span>{" "}
                  del cliente
                </div>

                {Comments && Comments.length > 0 ? (
                  <div className="col-12 d-flex justify-content-center my-2">
                    <div className="col-6 d-flex flex-column  px-3">
                      <PerfectScrollbar
                        className="my-2"
                        style={{ height: "calc(40vmin + 0.5vw)" }}
                      >
                        {Comments &&
                          Comments.map((comment, index) => (
                            <div
                              key={index}
                              className=" d-flex  py-2"
                              style={{
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                setcommentSelected(
                                  Comments.filter(
                                    (com) => com.id == comment.id
                                  )[0]
                                )
                              }
                            >
                              <ArrowLesion />
                              <div
                                style={{
                                  textAlign: "left",
                                  marginLeft: "1rem",
                                  lineHeight: "1",
                                  fontSize: "calc(0.3rem + 0.5vw)",
                                }}
                              >
                                {/* {comment.trainer?.replace(/<[^>]*>/, "")}  */}
                                {comment?.type == 10
                                  ? "Comentarios sobre mi entrenamiento"
                                  : comment?.type == 20
                                  ? "Preguntas sobre los servicios"
                                  : "Observaciones o quejas"}
                                <br></br>
                                <span
                                  style={{
                                    color: "#9DACBD",
                                    fontSize: "calc(0.3rem + 0.5vw)",
                                  }}
                                >
                                  {Moment(comment.date).format(
                                    "DD-MM-YYYY HH:mm"
                                  )}
                                </span>
                              </div>
                            </div>
                          ))}
                      </PerfectScrollbar>
                    </div>
                    <div className="separador-convenios"></div>
                    <div className="col-6 px-3">
                      <PerfectScrollbar
                        className="pe-4 d-flex flex-column align-items-center"
                        style={{ height: "calc(40vmin + 0.5vw)" }}
                      >
                        <div
                          className="title col-12 mb-3"
                          style={{
                            lineHeight: "100%",
                            textAlign: "left",
                            fontSize: "calc(0.6vmin + 0.6vw)",
                            color: "#394F65",
                          }}
                        >
                          {commentSelected?.type == 10
                            ? "Comentarios sobre mi entrenamiento"
                            : commentSelected?.type == 20
                            ? "Preguntas sobre los servicios"
                            : "Observaciones o quejas"}
                          <br></br>
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "calc(0.6vmin + 0.6vw)",
                              color: "#394F65",
                            }}
                          >
                            {Moment(commentSelected?.date).format(
                              "DD-MM-YYYY HH:mm"
                            )}
                          </span>
                        </div>
                        <div
                          className="mb-2"
                          style={{
                            color: "#394F65",
                            textAlign: "left",
                            fontSize: "calc(0.3rem + 0.5vw)",
                            marginRight: "auto",
                          }}
                        >
                          <span className="fw-bold">
                            Observaciones generales
                          </span>
                        </div>
                        <div
                          className="px-2 py-3 w-100"
                          style={{
                            background: "#F1F6F9",
                            borderRadius: "20px",
                            color: "#394F65",
                            fontSize: "calc(0.3rem + 0.5vw)",
                            textAlign: "left",
                          }}
                        >
                          {commentSelected?.comment}
                        </div>
                      </PerfectScrollbar>
                    </div>
                  </div>
                ) : (
                  <div className="w-100" style={{ textAlign: "center" }}>
                    {IdCustomer ? (
                      <span>
                        <em>No se encontraron registros</em>
                      </span>
                    ) : (
                      <span>
                        <em>Selecciona un atleta</em>
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                width: "30%",
                borderLeft: "1px solid #9DACBD",
                margin: "2% 0",
                padding: laptop1350.matches ? "auto" : "0 5%",
              }}
            >
              <div
                className="title col-12 my-2"
                style={{
                  lineHeight: "100%",
                  textAlign: "center",
                  fontSize: "calc(0.6vmin + 0.6vw)",
                }}
              >
                Notificaciones
              </div>
              <br></br>
              {notifications.length > 0 ? (
                <PerfectScrollbar
                  className="title col-12 my-2 mx-auto"
                  style={{
                    lineHeight: "100%",
                    textAlign: "center",
                    maxWidth: "20rem",
                    height: "70vh",
                  }}
                >
                  {notifications &&
                    notifications.map((noti, index) => (
                      <>
                        <div className="d-flex">
                          <div
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              margin: "auto 1rem auto auto",
                            }}
                          >
                            <Campana />
                          </div>
                          <span
                            key={index}
                            style={{
                              fontSize: "0.8rem",
                              color: "#9DACBD",
                              textAlign: "left",
                            }}
                          >
                            {" "}
                            {noti.message}{" "}
                          </span>
                        </div>
                        <hr></hr>
                      </>
                    ))}
                </PerfectScrollbar>
              ) : (
                <div>
                  <span
                    style={{
                      fontSize: "1rem",
                      color: "#9DACBD",
                      textAlign: "left",
                    }}
                  >
                    {" "}
                    No tienes notificaciones
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Notifications;
