import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ButtonMob } from "../../utils/utils";

export default function TrainingPlanCalendar(props) {
  const data = props.data;
  const anchoGrid = props.anchoGrid;
  const widthBox = props.widthBox || "100%";
  const minWidthBox = props.minWidthBox || "auto";
  const deleteFunction = props.deleteFunction;
  const updateFunction = props.updateFunction;
  const showForm = props.showForm;
  const defaultValues = props.defaultValues;

  return (
    <PerfectScrollbar
      className="w-100 px-3"
      style={{ height: "calc(75vh - 60px )" }}
    >
      <div className="box-text mb-2">Plan de entrenamiento membresia</div>
      <ButtonMob
        colorTextButton="#fff"
        colorButton="var(--elevvabutonLogin_User)"
        text="Agregar entrenamiento"
        sizeFont="0.8rem"
        className="mb-3"
        handleClick={() => {
          defaultValues();
          showForm(true);
        }}
      />
      <div
        className="grid-container-calendar justify-content-center m-auto"
        style={{ width: anchoGrid }}
      >
        {data &&
          data.map((elemento, index) => (
            <div className="grid-item-perfil" key={index}>
              <div className="box" style={{ border: "0.2px solid #B6C1CE" }}>
                <div
                  className={`box-text`}
                  style={{
                    margin: "0px",
                    padding: "10px",
                    position: "relative",
                    zIndex: "1",
                    fontSize: "calc(.4rem + .4vw)",
                    background: "#fff",
                    color: "var(--elevvabutonLogin_User)",
                    minWidth: minWidthBox,
                    width: widthBox,
                    justifyContent: "left"
                  }}
                >
                  Día {elemento.day}
                </div>
                <div
                  className={`box-text`}
                  style={{
                    margin: "0px",
                    padding: "10px",
                    color: "#fff",
                    pointerEvents: "none",
                    fontSize: "calc(0.5rem + 0.3vw)",
                    background: "rgb(49, 119, 190)",
                    minWidth: minWidthBox,
                    width: widthBox,
                    justifyContent: "left"
                  }}
                >
                  {elemento.date ? new Date(elemento.date).toLocaleDateString('es-CO', { year:"numeric", month:"numeric", day:"numeric"}) : "Sin fecha"}
                </div>
                <div
                  className={`box-text`}
                  style={{
                    gap: "10px",
                    justifyContent: "flex-end",
                    fontSize: "calc(1rem + .4vw)",
                    background: "#fff",
                    color: "var(--elevvabutonLogin_User)",
                    minWidth: minWidthBox,
                    width: widthBox,
                  }}
                >
                  <i
                    className="bi bi-pencil-square calendar-icon"
                    onClick={() => updateFunction(index)}
                  />
                  <i
                    className="bi bi-trash3 calendar-icon"
                    onClick={() => deleteFunction(index)}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </PerfectScrollbar>
  );
}
