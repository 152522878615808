import { ButtonArrow, laptop1600, urlImages } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import "./swiper-exercise.css";
const UserDetail = (props) => {
  const navigate = useNavigate();
  const userDetail = props.userDetail;

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center gap-2 mb-2">
        {" "}
        <ButtonArrow
          classes=" btnBackPr"
          colorBack={`var(--elevvaArrowColor)`}
          handleClick={() => navigate(-1)}
        />
        <span>
          <strong>Records del atleta</strong>
        </span>
      </div>

      <div className="d-flex align-items-center gap-3">
        <div className="d-flex align-items-center">
          <img
            className="ms-2 me-1"
            style={{
              objectFit: "cover",
              width: laptop1600.matches ? "70px" : "55px",
              maxHeight: "100%",
              maxWidth: "100%",
              height: laptop1600.matches ? "70px" : "55px",
              borderRadius: "50%",
            }}
            src={
              `${urlImages}${userDetail?.profile_pic}` ||
              "/assets/logo-supremacy.png"
            }
          ></img>{" "}
        </div>
        <div className="d-flex flex-column">
          <span style={{ color: "#394F65" }}>
            <strong>{userDetail?.full_name}</strong>
          </span>
          <span style={{ color: "#394F65" }}>
            {userDetail?.membership_name}
          </span>
        </div>
      </div>
    </div>
  );
};
export default UserDetail;
