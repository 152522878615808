import { useEffect, useState } from "react";
import { ReactComponent as ArrowDown } from "../../../assets/elevva-pro-icons/arrowDown.svg";
import { ReactComponent as SearchIconAlone } from "../../../assets/elevva-pro-icons/searchIconAlone.svg";
import TableCustom from "../../../utils/table-custom";
import { ButtonMob, instanceMob, Loadingspinner } from "../../../utils/utils";

import { Spinner } from "react-bootstrap";
import { DropdownList } from "react-widgets";
import changePage from "../functions/changePage";
import ModalAssignPlan from "./modal-assign-plan";
import ModalCustom from "./modal-custom";
import ConfirmDeletionModal from "./modal-delete";
import ModalExpiredPlans from "./modal-expired-plans";

const MembershipsUsers = () => {
  const [users, setUsers] = useState([]);
  const [plans, setPlans] = useState([]);

  const [usersExpired, setUsersExpired] = useState({});
  const [userNearExpired, setUserNearExpired] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [userDetail, setUserDetail] = useState(null);
  const [pageNext, setPageNext] = useState(null);
  const [pageBack, setPageBack] = useState(null);
  const [dataBackup, setDataBackup] = useState({});
  const [modal, setModal] = useState(null);
  const [modalAssign, setModalAssign] = useState(null);
  const [modalDelete, setModalDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loaderDelete, setLoaderDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ErroraGregado, seterroraGregado] = useState("");
  const [loader, setLoader] = useState(false);

  function getUsersPage(value) {
    let url = value ? value : `admin/membership`;

    instanceMob
      .get(url)
      .then((res) => res.data)
      .then((data) => {
        setUsers(data.results);
        setPageNext(data.next);
        setPageBack(data.previous);
      })
      .catch((err) => console.error(err));
  }
  function getPlans() {
    setLoading(true);
    instanceMob
      .get(`admin/plan`)
      .then((res) => res.data)
      .then((data) => {
        setPlans(data.results);

        setLoading(false);
      })
      .catch((err) => console.error(err));
  }

  function getUsersExpired() {
    instanceMob
      .get(`admin/membership/plan/expiration?filter=expired`)
      .then((res) => res.data)
      .then((data) => {
        setUsersExpired(data);
      })
      .catch((err) => console.error(err));
  }
  function getUsersNearExpired() {
    instanceMob
      .get(`admin/membership/plan/expiration?filter=near_to_expire`)
      .then((res) => res.data)
      .then((data) => {
        setUserNearExpired(data);
      })
      .catch((err) => console.error(err));
  }
  function getUsers() {
    instanceMob
      .get(`admin/membership`)
      .then((res) => res.data)
      .then((data) => {
        setUsers(data.results);

        setCount(data.count);
        setPageNext(data.next);
        setPageBack(data.previous);
        setDataBackup(data);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }
  useEffect(() => {
    if (modal && loaderDelete) renewUser();
  }, [loaderDelete]);
  useEffect(() => {
    setIsLoading(true);
    getUsers();
    getPlans();
    getUsersExpired();
    getUsersNearExpired();
  }, []);
  useEffect(() => {
    if (modal && userSelected) {
      editUser(userSelected);
    }
  }, [loader]);

  function planUserChange(event, user) {
    setLoader(true);
    let formData = new FormData();
    const { value, name } = event.target[0];
    formData.append(name, value);
    formData.append("customer", user.customer_id);
    instanceMob
      .post(`admin/membership/plan/change`, formData)
      .then((res) => {
        if (res.status == "204" || res.status == "201") {
          setLoader(false);
          setModal(null);

          setModalDelete(
            <ConfirmDeletionModal
              onCancel={() => setModalDelete(null)}
              onConfirm={() => setModalDelete(null)}
              loader={false}
            >
              {/* Inserta cualquier contenido aquí como children */}
              {user.customer_name}
              <br></br>
              <strong>asignado correctamente </strong>
            </ConfirmDeletionModal>
          );
          getUsers();
        }
      })
      .catch((err) => {
        let msg_error = err.response.data;
        if (msg_error) {
          seterroraGregado(msg_error);
        }
      });
  }
  function editUser(user) {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setUserSelected(user);

    setModal(
      <div
        className="popUpServices justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
            maxWidth: "40rem",
            minWidth: "35rem",
          }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "1%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => {
              setModal(null);
              setLoader(false);
            }}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div
            className=" mb-3 py-3"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            Asignar <strong>plan</strong>
          </div>
          <div className="d-flex  w-100 my-2 text-center">
            <span>{user.customer_name}</span>
          </div>
          <form
            id="form-password-change"
            onSubmit={(e) => {
              e.preventDefault();
              planUserChange(e, user);
            }}
          >
            <div className="d-flex  w-100 my-2" id="location-select">
              {loading ? (
                <Spinner />
              ) : (
                <DropdownList
                  style={{ width: "100%" }}
                  busy
                  name="plan"
                  defaultValue={
                    user?.id
                      ? plans
                          .filter((memb) => memb.id == user.id)
                          .map((memb) => ({
                            id: memb.id,
                            name: memb.name,
                            value: memb.id,
                          }))[0]
                      : ""
                  }
                  busySpinner={<ArrowDown></ArrowDown>}
                  className={"input-style-elevva-graphics"}
                  id="optionSummary"
                  placeholder={"Seleccionar plan"}
                  data={plans.map((memb) => ({
                    id: memb.id,
                    name: memb.name,
                    value: memb.id,
                  }))}
                  dataKey="id"
                  textField="name"
                  // onChange={(value) => serviceSelector(value.value)}
                  renderListItem={({ item }) => (
                    <div
                      className="w-100 mb-1"
                      style={{ borderBottom: "1px solid #9DACBD" }}
                    >
                      <div>{item.name}</div>
                    </div>
                  )}
                />
              )}
            </div>

            <div className="col-12">
              <div className="col-12 btnAction_pruebas letraspopfi2">
                <ButtonMob
                  colorButton={"var(--elevvabutonLogin_User)"}
                  text={loader ? "Cargando..." : "Confirmar"}
                  className={"mb-3 mt-3 letraspopfi2actualizar"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
  function renewUser(user) {
    const handleDelete = () => {
      setLoaderDelete(true);
      let formData = new FormData();
      formData.append("customer", user.customer_id);
      instanceMob
        .post(`admin/membership/plan/renew`, formData)
        .then((res) => {
          if (res.status == "204" || res.status == "201") {
            setLoaderDelete(false);
            getPlans();
          }
        })
        .catch((err) => {
          let msg_error = err.response.data;
          if (msg_error) {
            seterroraGregado(msg_error);
          }
        });
    };

    setModal(
      user ? (
        <ConfirmDeletionModal
          onCancel={() => setModal(null)}
          onConfirm={handleDelete}
          loader={loaderDelete}
        >
          {/* Inserta cualquier contenido aquí como children */}
          ¿Estas seguro de <br></br>
          <strong>renovar el plan de {user?.customer_name}? </strong>
        </ConfirmDeletionModal>
      ) : (
        <ConfirmDeletionModal
          onCancel={() => setModal(null)}
          onConfirm={() => setModal(null)}
          loader={false}
        >
          <br />
          Plan de usuario
          <br />
          <strong> renovado</strong>
          <br />
          <strong>correctamente.</strong>
          <br />
        </ConfirmDeletionModal>
      )
    );
  }
  function showExpired() {
    setModal(
      <ModalCustom
        onCancel={() => setModal(null)}
        onConfirm={() => setModal(null)}
        loader={false}
      >
        <ModalExpiredPlans filter={"expired"} />
      </ModalCustom>
    );
  }
  function showNotExpired() {
    setModal(
      <ModalCustom
        onCancel={() => setModal(null)}
        onConfirm={() => setModal(null)}
        loader={false}
      >
        <ModalExpiredPlans filter={"near_to_expire"} />
      </ModalCustom>
    );
  }
  const [userNoPLan, setUserNoPlan] = useState(null);
  const [planUserNoPlan, setPlanUserNoPlan] = useState(null);

  function assignPlanToUser(user, plan) {
    let formData = {
      plan: plan,
      customer: user,
    };
    setLoader(true);
    instanceMob
      .post(`admin/membership/plan/create`, formData)
      .then((res) => {
        if (res.status == "204" || res.status == "201") {
          setLoader(false);
          setModalAssign(null);
          setModal(
            <ModalCustom
              onCancel={() => {
                setModal(null);
                setUserNoPlan(null);
                setPlanUserNoPlan(null);
              }}
              onConfirm={() => {
                setModal(null);
                setUserNoPlan(null);
                setPlanUserNoPlan(null);
              }}
              loader={false}
            >
              <br />
              El Plan ha sido
              <br />
              <strong> asignado</strong>
              <br />
              <strong>correctamente.</strong>
              <br />
            </ModalCustom>
          );
        }
      })
      .catch((err) => {
        let msg_error = err.response.data;
        if (msg_error) {
          setModalAssign(null);
          setModal(
            <ModalCustom
              onCancel={() => setModal(null)}
              onConfirm={() => setModal(null)}
              loader={false}
            >
              {msg_error ? (
                msg_error
              ) : (
                <>
                  {" "}
                  <br />
                  Algo salió mal
                  <br />
                  <strong> intentalo</strong>
                  <br />
                  <strong>de nuevo.</strong>
                  <br />
                </>
              )}
            </ModalCustom>
          );
        }
      });
  }

  useEffect(() => {
    if (userNoPLan || planUserNoPlan) {
      assignPlan();
    }
  }, [userNoPLan, planUserNoPlan]);
  useEffect(() => {
    if (modalAssign) {
      assignPlan();
    }
  }, [loader]);

  function assignPlan() {
    setModalAssign(
      <ModalCustom
        onCancel={() => {
          setUserNoPlan(null);
          setPlanUserNoPlan(null);
          setModalAssign(null);
        }}
        onConfirm={() => {
          if (userNoPLan && planUserNoPlan) {
            assignPlanToUser(userNoPLan, planUserNoPlan);
          }
        }}
        loader={loader}
      >
        <ModalAssignPlan
          userNoPLan={userNoPLan}
          planUserNoPlan={planUserNoPlan}
          setUserNoPlan={setUserNoPlan}
          setPlanUserNoPlan={setPlanUserNoPlan}
        />
      </ModalCustom>
    );
  }

  function deleteUser(user) {
    const handleDelete = () => {
      setLoaderDelete(true);
      let formData = new FormData();
      formData.append("customer", user.customer_id);
      formData.append("plan", user.id);

      instanceMob
        .post(`admin/membership/plan/deactivate`)
        .then((res) => {
          if (res.status == "204" || res.status == "200") {
            setLoaderDelete(false);
            getPlans();
          }
        })
        .catch((err) => {
          let msg_error = err.response.data;
          if (msg_error) {
            seterroraGregado(msg_error);
          }
        });
    };

    setModal(
      user ? (
        <ConfirmDeletionModal
          onCancel={() => setModal(null)}
          onConfirm={handleDelete}
          loader={loaderDelete}
        >
          {/* Inserta cualquier contenido aquí como children */}
          ¿Estas seguro de <br></br>
          <strong>eliminar {user?.name || "el plan"}? </strong>
        </ConfirmDeletionModal>
      ) : (
        <ConfirmDeletionModal
          onCancel={() => setModal(null)}
          onConfirm={() => setModal(null)}
          loader={false}
        >
          <br />
          Plan
          <br />
          <strong> eliminada</strong>
          <br />
          <strong>correctamente.</strong>
          <br />
        </ConfirmDeletionModal>
      )
    );
  }

  return (
    <>
      {modal}
      {modalDelete}
      {modalAssign}
      <div
        style={{
          width: "50%",
          borderRadius: "10px",
          height: "100%",
        }}
      >
        <div className="h-100">
          <div className="my-2 px-2" style={{ height: "22%" }}>
            <span
              style={{
                fontSize: "calc(.4rem + .4vw)",
                color: "var(--elevvaTextTitle)",
              }}
            >
              <div className="d-flex gap-3 w-100">
                <div
                  className="d-flex  align-items-center justify-content-center flex-column"
                  style={{
                    width: "50%",
                    border: "1px solid #B6C1CE",
                    borderRadius: "10px",
                    height: "100%",
                  }}
                >
                  <div className="my-2">
                    <span
                      style={{
                        color: "#394F65",
                        fontSize: "calc(.45rem + .45vw)",
                      }}
                    >
                      Usuarios vencidos
                    </span>
                  </div>
                  <div
                    className="w-100 text-center my-2 py-2"
                    style={{ background: "#DFDFDF" }}
                  >
                    <span
                      style={{
                        color:
                          usersExpired?.results?.length > 0 ? "red" : "#394F65",
                        fontSize: "calc(.6rem + .6vw)",
                        fontWeight: "bold",
                      }}
                    >
                      {usersExpired?.results?.length || 0}
                    </span>
                  </div>
                  <div className="my-2">
                    <button
                      style={{
                        fontSize: "calc(.33rem + .32vw)",
                        marginLeft: "auto",
                      }}
                      className="  d-flex add_location px-4 py-1"
                      onClick={showExpired}
                    >
                      Ver más
                    </button>
                  </div>
                </div>
                <div
                  className="d-flex  align-items-center justify-content-center flex-column"
                  style={{
                    width: "50%",
                    border: "1px solid #B6C1CE",
                    borderRadius: "10px",
                    height: "100%",
                  }}
                >
                  <div className="my-2">
                    <span
                      style={{
                        color: "#394F65",
                        fontSize: "calc(.45rem + .45vw)",
                      }}
                    >
                      Usuarios próximos a vencer
                    </span>
                  </div>
                  <div
                    className="w-100 text-center my-2 py-2"
                    style={{ background: "#DFDFDF" }}
                  >
                    <span
                      style={{
                        color:
                          userNearExpired?.results?.length > 0
                            ? "red"
                            : "#394F65",
                        fontSize: "calc(.6rem + .6vw)",
                        fontWeight: "bold",
                      }}
                    >
                      {userNearExpired?.results?.length || 0}
                    </span>
                  </div>
                  <div className="my-2">
                    <button
                      style={{
                        fontSize: "calc(.33rem + .32vw)",
                        marginLeft: "auto",
                      }}
                      className="  d-flex add_location px-4 py-1"
                      onClick={showNotExpired}
                    >
                      Ver más
                    </button>
                  </div>
                </div>
              </div>
            </span>
          </div>

          <div className="my-2 px-2" style={{ height: "75%" }}>
            <div
              style={{
                width: "100%",
                border: "1px solid #B6C1CE",
                borderRadius: "10px",
                height: "100%",
              }}
            >
              <div
                className="d-flex  p-2"
                style={{
                  justifyContent: "space-between",
                  width: "50%",
                  marginLeft: "auto",
                }}
              >
                <div>
                  <span
                    style={{
                      fontSize: "calc(.4rem + .4vw)",
                      color: "var(--elevvaTextTitle)",
                    }}
                  >
                    Usuarios
                  </span>
                </div>
                <button
                  style={{ marginRight: "2%", padding: "0.5rem 1rem" }}
                  className="d-flex add_location"
                  onClick={assignPlan}
                >
                  Asignar plan +
                </button>
              </div>
              <div
                style={{ justifyContent: "flex-end" }}
                className="d-flex mx-3 my-2"
              >
                <div
                  className="mx-3"
                  style={{ position: "relative", marginLeft: "auto" }}
                >
                  <input
                    placeholder="Buscar usuario"
                    type="search"
                    className="searchUsers "
                    style={{
                      border: "1px solid var(--elevvaTextSubtitle)",
                      borderRadius: "20px",
                      fontSize: "calc(.33rem + .32vw)",
                      padding: "0.4rem 1rem",
                    }}
                    onChange={() => {}}
                  ></input>
                  <span className="search-icon-admin">
                    <SearchIconAlone fontSize={10} />
                  </span>
                </div>{" "}
                {/* <div className="d-flex align-items-center me-1">
                  <FiltersTable
                    style={{ width: "100%", padding: "2%" }}
                    className="cursor-pointer"
                    onClick={() => {}}
                  />
                </div> */}
              </div>
              <div
                className="py-2"
                style={{ minHeight: "50vh", maxHeight: "50vh" }}
              >
                {isLoading ? (
                  <Loadingspinner customStyle="grow" size="2.5rem" />
                ) : (
                  <TableCustom
                    editUser={editUser}
                    deleteUser={deleteUser}
                    renewUser={renewUser}
                    changePage={(value) =>
                      changePage(
                        value,
                        getUsersPage,
                        pageNext,
                        pageBack,
                        page,
                        count,
                        setPage
                      )
                    }
                    count={count}
                    page={page}
                    users={users}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MembershipsUsers;
