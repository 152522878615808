import jsPDF from "jspdf";
import { instance } from "../../utils/utils";

const generatePdf = async (IdCustomer) => {
  let doc = new jsPDF("p", "mm", "a4");

  const htmlReport = await instance
    .get(`memberships/corp/company/members/report?customer_id=${IdCustomer}`)
    .then((res) => {
      return res.data;
    });

  var ifrm = document.getElementById("printf");
  if (!ifrm) {
    var ifrm = document.createElement("iframe");
    ifrm.setAttribute("id", "printf");
    ifrm.style.display = "none";
  }
  document.body.appendChild(ifrm);

  ifrm.contentWindow.document.write(htmlReport);
  ifrm.contentWindow.document.close();

  ifrm.contentWindow.print();
};

export default generatePdf;
